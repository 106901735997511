import React from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import heroImage from "../assets/images/Hero/invest.jpg";
import We from "../components/We";
import FooterHero from "../components/FooterHero";
import opportunityImage from "../assets/images/invest/opportunity-img.png";
import strategiesImageDT from "../assets/images/invest/investment-strategies-dt.png";
import strategiesImageMB from "../assets/images/invest/investment-strategies-mb.png";
import bgImage from "../assets/images/FooterHero/invest.jpg";
import SlidingLogo from "../components/SlidingLogo";
import Navbar from "../components/Navbar";
import Chat from "../components/Chat";

function InvestPage() {
    return (
        <div className="investpage">
            <SlidingLogo />
            <Navbar />
            <Hero
                heroImage={heroImage}
                heroTexts={"Por qué"}
                heroColoredTexts={"Invertir."}
            />
            <We
                text={
                    "Como clase de activo, los bienes raíces históricamente han ofrecido una combinación de atractivos rendimientos ajustados al riesgo con mayores ingresos y estabilidad que las acciones que se cotizan en bolsa."
                }
            />
            <section className="why-invest-section">
                <div className="container">
                    <div className="why-invest-content">
                        <h5 className="title-bottom-border">
                            Beneficios de invertir en bienes raíces
                        </h5>
                        <div className="why-invest-container">
                            <div className="why-invest">
                                <div className="why-invest-img">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="96.376"
                                        height="88.512"
                                        viewBox="0 0 96.376 88.512"
                                    >
                                        <g
                                            id="Group_1819"
                                            data-name="Group 1819"
                                            transform="translate(1.401 0.999)"
                                        >
                                            <path
                                                id="Path_1559"
                                                data-name="Path 1559"
                                                d="M3132.887,614.331a5.429,5.429,0,0,0-1.555-2.125,5.849,5.849,0,0,0-5.646-.723,6.269,6.269,0,0,0-2.524,1.565l-12.669,12.733c-.01-.13-.021-.26-.037-.385a6.165,6.165,0,0,0-6.318-5.283l-13.946.254a56.659,56.659,0,0,1-11.246-1.244c-13.1-2.645-20.722,9.95-20.8,10.077l.493.292c.074-.124,7.5-12.373,20.192-9.808a57.162,57.162,0,0,0,11.365,1.254l13.951-.254a5.593,5.593,0,0,1,5.74,4.784,7.73,7.73,0,0,1,.061.976v.143a5.7,5.7,0,0,1-5.711,5.569h-12.779v.573h12.779a6.276,6.276,0,0,0,6.284-6.135v-.031l13.047-13.113a5.7,5.7,0,0,1,2.291-1.423,5.309,5.309,0,0,1,5.1.617,4.859,4.859,0,0,1,1.392,1.9,5.64,5.64,0,0,1-1.007,5.755l-13.106,15.709c-.039.064-3.939,6.351-9.25,6.351-.87,0-2.333-.036-4.184-.081-15.544-.379-30.14-.35-32.414,2.3l-19.97-18.541-13,13.511,8.567,8.567.406-.405-8.169-8.169,12.225-12.7,22.4,20.8-12.1,13.047L3059,657.1l-.4.414,4.171,4,12.887-13.894-2.846-2.642c2.093-2.845,22.964-2.339,31.979-2.119,1.856.045,3.322.081,4.2.081,5.635,0,9.575-6.355,9.715-6.591l13.082-15.674A6.21,6.21,0,0,0,3132.887,614.331Z"
                                                transform="translate(-3039.42 -575.425)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                            <path
                                                id="Path_1560"
                                                data-name="Path 1560"
                                                d="M3120.545,586.018a18.564,18.564,0,1,0-18.564-18.565A18.586,18.586,0,0,0,3120.545,586.018Zm0-36.555a17.991,17.991,0,1,1-17.991,17.991A18.011,18.011,0,0,1,3120.545,549.462Z"
                                                transform="translate(-3073.593 -548.888)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                            <path
                                                id="Path_1561"
                                                data-name="Path 1561"
                                                d="M3130.379,574h.067a3.639,3.639,0,0,1,3.691,2.717,4.516,4.516,0,0,1-.335,3.609,3.7,3.7,0,0,1-3.461,1.938c-3.727,0-3.877-3.732-3.882-3.891l-.573.015c0,.044.17,4.282,4.239,4.434v.759h.573v-.769a4.25,4.25,0,0,0,3.6-2.206,5.088,5.088,0,0,0,.38-4.068,4.173,4.173,0,0,0-4.236-3.113h-.067a3.64,3.64,0,0,1-3.692-2.716,4.518,4.518,0,0,1,.335-3.609,3.7,3.7,0,0,1,3.461-1.938c3.727,0,3.878,3.732,3.882,3.891l.573-.015c0-.044-.17-4.283-4.239-4.434v-1.036h-.573v1.047a4.246,4.246,0,0,0-3.6,2.206,5.089,5.089,0,0,0-.38,4.068A4.173,4.173,0,0,0,3130.379,574Z"
                                                transform="translate(-3083.792 -555.153)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                            <rect
                                                id="Rectangle_1545"
                                                data-name="Rectangle 1545"
                                                width="0.573"
                                                height="7.961"
                                                transform="translate(8.051 63.145) rotate(-47.143)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </svg>
                                </div>

                                <h4 className="colored-text">Ingresos</h4>
                                <p className="para">
                                    Año tras año, el sector inmobiliario ha
                                    demostrado su capacidad para ofrecer flujos
                                    de ingresos superiores a los inversionistas.
                                </p>
                            </div>

                            <div className="why-invest">
                                <div className="why-invest-img">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="69.121"
                                        height="89.254"
                                        viewBox="0 0 69.121 89.254"
                                    >
                                        <path
                                            id="Path_1558"
                                            data-name="Path 1558"
                                            d="M3323.872,633.478a15.652,15.652,0,0,0-11.066-8.374l.156-.008-2.279-48.685h2.823a3.249,3.249,0,0,0,3.246-3.245v-.08a3.25,3.25,0,0,0-1.939-2.969l3.809-8.29h.1V546.64H3310.6v7.451h-6.837V546.64h-9.195v7.451h-6.836V546.64h-9.195v7.451H3271.7V546.64h-8.126v15.187h.1l3.809,8.29a3.249,3.249,0,0,0-1.94,2.969v.08a3.249,3.249,0,0,0,3.246,3.245h2.823l-2.279,48.685.155.008a15.65,15.65,0,0,0-11.064,8.374l-.211.416h65.866Zm-59.725-86.264h6.98v7.451h7.983v-7.451h8.048v7.451h7.983v-7.451h8.048v7.451h7.983v-7.451h6.979v14.04h-54Zm.16,14.613h53.683l-3.725,8.107a3.221,3.221,0,0,0-.76-.093h-44.713a3.244,3.244,0,0,0-.759.093Zm4.485,14.011a2.675,2.675,0,0,1-2.672-2.672v-.08a2.675,2.675,0,0,1,2.672-2.672h44.713a2.675,2.675,0,0,1,2.673,2.672v.08a2.675,2.675,0,0,1-2.673,2.672h-44.713Zm3.4.573h37.919l2.276,48.618a15.645,15.645,0,0,0-2.491-.206H3272.4a15.645,15.645,0,0,0-2.491.206Zm-13.033,56.91A15.038,15.038,0,0,1,3272.4,625.4h37.489a15.04,15.04,0,0,1,13.248,7.924Z"
                                            transform="translate(-3256.587 -545.64)"
                                            fill="#a88e68"
                                            stroke="#a88e68"
                                            strokeWidth="2"
                                        />
                                    </svg>
                                </div>

                                <h4 className="colored-text">Estabilidad</h4>
                                <p className="para">
                                    Mientras que otros tipos de inversiones
                                    zigzaguean, los bienes raíces tienen la
                                    reputación de mantenerse estables.
                                </p>
                            </div>

                            <div className="why-invest">
                                <div className="why-invest-img">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="75.339"
                                        height="88.307"
                                        viewBox="0 0 75.339 88.307"
                                    >
                                        <g
                                            id="Group_1821"
                                            data-name="Group 1821"
                                            transform="translate(1.414 1.343)"
                                        >
                                            <path
                                                id="Path_1554"
                                                data-name="Path 1554"
                                                d="M3546.535,595.4v59.3h18.473V595.4Zm17.9,58.725h-17.326V595.974h17.326Z"
                                                transform="translate(-3492.083 -568.736)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                            <path
                                                id="Path_1555"
                                                data-name="Path 1555"
                                                d="M3500.493,665.075h18.473V619.717h-18.473Zm.573-44.785h17.326V664.5h-17.326Z"
                                                transform="translate(-3472.438 -579.111)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                            <path
                                                id="Path_1556"
                                                data-name="Path 1556"
                                                d="M3454.335,674.84h18.473V642.6h-18.473Zm.573-31.664h17.326v31.091h-17.326Z"
                                                transform="translate(-3452.743 -588.875)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                            <path
                                                id="Path_1557"
                                                data-name="Path 1557"
                                                d="M3523.193,549.83V561h.573V548.889h-11.281v.573h10.261l-31.972,29.5-9.515-10.422-10.4,10.2.4.409,9.979-9.781,9.5,10.41Z"
                                                transform="translate(-3459.792 -548.889)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                            <rect
                                                id="Rectangle_1541"
                                                data-name="Rectangle 1541"
                                                width="9.764"
                                                height="0.573"
                                                transform="matrix(0.714, -0.7, 0.7, 0.714, 0, 40.688)"
                                                fill="#a88e68"
                                                stroke="#a88e68"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <h4 className="colored-text">
                                    Rentabilidad ajustada al riesgo
                                </h4>
                                <p className="para">
                                    Los bienes raíces privados generalmente
                                    mitigan el riesgo al mismo tiempo que
                                    priorizan los rendimientos atractivos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="opportunity-section">
                <div className="container">
                    <div className="opportunity-content">
                        <h5>oportunidad</h5>
                        <h2>
                            Diseñamos para <br />
                            aprovechar el <br />
                            potencial del <br />
                            <span className="colored-text">mercado.</span>
                        </h2>
                        <p>
                            Los bienes raíces en los que hemos invertido hasta
                            ahora han sido elegidos estratégicamente para
                            capitalizar las demandas generadas por las
                            tendencias demográficas a largo plazo. Creemos que
                            este enfoque en los activos reales en la economía
                            real se refleja en los resultados que hemos logrado
                            para nuestros inversionistas.
                        </p>
                        <img src={opportunityImage} alt="Opportunity" />
                    </div>
                </div>
            </section>

            <section className="investment-strategies-section">
                <div className="container">
                    <div className="investment-strategies-content">
                        <h2>
                            Estrategias <br />
                            <span className="colored-text">de inversión</span>
                        </h2>

                        <div className="point">
                            <h6>01.</h6>
                            <p className="para">
                                Con una combinación de estrategias, nuestro
                                objetivo es ofrecer resultados consistentes y
                                sólidos en función de las metas y el apetito por
                                el riesgo de nuestros inversionistas.
                            </p>
                        </div>

                        <picture>
                            <source
                                media="(min-width: 768px)"
                                srcSet={strategiesImageDT}
                            />
                            <img
                                src={strategiesImageMB}
                                alt="Investment Strategies"
                            />
                        </picture>

                        <div className="point">
                            <h6>02.</h6>
                            <p className="para">
                                Los bienes raíces tienen una reputación bien
                                ganada por ser una fuente confiable de ingresos
                                pasivos. De hecho, el componente de ingresos del
                                NPI (el índice que rastrea el desempeño de
                                bienes raíces privados) ha promediado una tasa
                                más alta que los rendimientos de otras clases de
                                activos principales.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterHero
                bgImage={bgImage}
                title={"EXPLORA LOS PROYECTOS"}
                heading={"nuestros proyectos"}
                text={"Invierte en los proyectos actuales o futuros."}
                link={""}
            />
            <Footer />
            <Chat />
        </div>
    );
}

export default InvestPage;
