import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import We from "../components/We";
import Footer from "../components/Footer";
import heroImage from "../assets/images/Hero/event.jpg";
import EventSlider from "../components/EventSlider";
import SlidingLogo from "../components/SlidingLogo";
import Chat from "../components/Chat";
import eventDates from "../data/eventDates";

function EventPage() {
    const styles = eventDates.map(
        (date) =>
            `button[aria-label="${date}"] {
            position: relative;
            color: var(--primaryColor) !important;
        }
    
        button[aria-label="${date}"]::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            content: "";
            width: 5px;
            height: 5px;
            background-color: var(--primaryColor);
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
        }`
    );

    const [calenderDate, setCalenderDate] = useState(new Date());
    return (
        <div className="eventpage">
            <style>{styles}</style>
            <SlidingLogo />
            <Navbar />
            <Hero
                heroImage={heroImage}
                heroTexts={"Descubre un mundo"}
                heroColoredTexts={"el eventos."}
            />
            <We
                heading={"EVENTOS"}
                text={
                    "Explora el calendario de eventos que tenemos para ti, mira los próximos eventos, disfruta, descubre y vive nuevas experiencias."
                }
            />
            <EventSlider
                eventDates={eventDates}
                calenderDate={calenderDate}
                setCalenderDate={setCalenderDate}
            />
            <Footer />
            <Chat />
        </div>
    );
}

export default EventPage;
