import React from "react";

function Contact(props) {
    return (
        <section className="contact-section">
            <div className="anchor" id="contact"></div>
            <div className="container">
                <div className="contact-content">
                    {props.title ? (
                        <h2>
                            {props.title} <br />
                            <span className="colored-text">
                                {props.coloredTitle}
                            </span>
                        </h2>
                    ) : (
                        ""
                    )}
                    <div className="contact">
                        <h5>DÉJANOS TUS DATOS Y TE CONTACTAREMOS</h5>
                        <form action="">
                            <input
                                type="email"
                                name="email"
                                placeholder="Correo electrónico"
                            />
                            <input
                                type="tel"
                                name="telephone"
                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                placeholder="Teléfono"
                            />

                            <div className="checkbox-container">
                                <input
                                    className="custom-checkbox"
                                    type="checkbox"
                                    id="subscribe"
                                    value="Subscribe"
                                />
                                <div>
                                    <span className="checkbox"></span>
                                    <span>Recibir las últimas noticias</span>
                                </div>
                                <label htmlFor="subscribe"></label>
                            </div>
                            <button className="submit-btn">
                                Enviar <br />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="62.353"
                                    height="62.353"
                                    viewBox="0 0 62.353 62.353"
                                >
                                    <g
                                        id="Group_1694"
                                        data-name="Group 1694"
                                        transform="translate(4 4)"
                                    >
                                        <path
                                            id="Path_1334"
                                            data-name="Path 1334"
                                            d="M0,38.433S28.812,9.853,38.433,0"
                                            transform="translate(27.176) rotate(45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1335"
                                            data-name="Path 1335"
                                            d="M0,0,27.323.06"
                                            transform="translate(35.802 7.813) rotate(45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1336"
                                            data-name="Path 1336"
                                            d="M0,0,27.2.06"
                                            transform="translate(35.802 46.37) rotate(-45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                    </g>
                                </svg>
                            </button>
                        </form>

                        <div className="contact-details">
                            <a href="tel:+12345632324">+1 234 563 23 34</a>
                            <br />
                            <a href="mailto:contacto@drimstay.com">
                                CONTACTO@DRIMSTAY.COM
                            </a>
                            <br />
                            <a href="https://www.drimstay.com">
                                WWW.DRIMSTAY.COM
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
