import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import company1 from "../assets/images/companies/company-1.png";
import company2 from "../assets/images/companies/company-2.png";
import company3 from "../assets/images/companies/company-3.png";
import company4 from "../assets/images/companies/company-4.png";
import company5 from "../assets/images/companies/company-5.png";
import company6 from "../assets/images/companies/company-6.png";

function Companies() {
    return (
        <section className="companies-section">
            <div className="container">
                <div className="companies-content">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={2}
                        breakpoints={{
                            640: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div className="company-slide">
                                <img src={company1} alt="Company" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="company-slide">
                                <img src={company2} alt="Company" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="company-slide">
                                <img src={company3} alt="Company" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="company-slide">
                                <img src={company4} alt="Company" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="company-slide">
                                <img src={company5} alt="Company" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="company-slide">
                                <img src={company6} alt="Company" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    );
}

export default Companies;
