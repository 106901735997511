import React from "react";
import teamVideo from "../assets/videos/team.mp4";

function Risks() {
    return (
        <section className="risky-section">
            <div className="container">
                <div className="risky-content">
                    <div className="point">
                        <h6>01.</h6>
                        <p className="para">
                            Estamos ubicados en Medellín, Colombia. Nos
                            dedicamos al desarrollo de proyectos inmobiliarios,
                            desde la estructuración, gerencia y venta de los
                            mismos.
                        </p>
                    </div>

                    <h2>
                        SOMOS CONSCIENTES, <br />
                        IRREVERENTES Y <br />
                        <span className="colored-text">ARRIESGADOS.</span>
                    </h2>

                    <div className="point">
                        <h6>02.</h6>
                        <p className="para">
                            Somos resilientes, aprendemos de los errores, nos
                            cuestionamos todos los días, y creemos que si nos
                            atrevemos a pensar diferente, logramos proyectos
                            extraordinarios. Somos Drimers, actuamos siempre con
                            transparencia, innovación, honestidad, consciencia,
                            respeto y verdad.
                        </p>
                    </div>

                    <video
                        src={teamVideo}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline
                    ></video>
                </div>
            </div>
        </section>
    );
}

export default Risks;
