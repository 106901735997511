import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./assets/css/reset.css";
import "./assets/css/style.css";
import Loading from "./containers/LoadingPage";
import Home from "./containers/HomePage";
import Event from "./containers/EventPage";
import Nosotros from "./containers/NosotrosPage";
import Carrera from "./containers/CarreraPage";
import Invest from "./containers/InvestPage";
import Property from "./containers/PropertyPage";
import Project from "./containers/ProjectPage";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Loading />} />
                <Route path="home" element={<Home />} />
                <Route path=":id" element={<Property />} />
                <Route path="project" element={<Project />} />
                <Route path="event" element={<Event />} />
                <Route path="team" element={<Nosotros />} />
                <Route path="invest" element={<Invest />} />
                <Route path="career" element={<Carrera />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
