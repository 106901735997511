import React, { useState, useRef } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import beginning1 from "../assets/images/beginning-1.jpg";
import beginningDT from "../assets/images/beginning-1-DT.jpg";
import beginning2 from "../assets/images/beginning-2.jpg";

function Beginning() {
    const [swiper, setSwiper] = useState();
    const imageViewer = useRef();
    const imageViewerNext = useRef();
    const imageViewerPrev = useRef();

    const imageViewerHandler = (imageIndex) => {
        imageViewer.current.style.display = "flex";
        document.body.style.overflow = "hidden";
        swiper.slideTo(imageIndex);
    };

    const closeImageViewer = () => {
        imageViewer.current.style.display = "none";
        document.body.style.overflow = "auto";
    };
    return (
        <section className="beginning-section">
            <div className="container">
                <div className="beginning-content">
                    <h5>El inicio</h5>
                    <h2>
                        UNA MEZCLA PERFECTA ENTRE <br />
                        LO SOFISTICADO Y LO <br />
                        <span className="colored-text">CONTEMPORÁNEO.</span>
                    </h2>
                    <div className="beginning">
                        <img
                            src={beginning1}
                            alt="Beginning"
                            onClick={() => {
                                imageViewerHandler(0);
                            }}
                        />
                        <div>
                            <h4>01.</h4>
                            <p className="para-f2">
                                Una nueva forma de vivir, <br />
                                explorar y habitar.
                            </p>
                        </div>
                    </div>

                    <div className="beginning">
                        <div>
                            <h4>02.</h4>
                            <p className="para-f2">
                                Tecnología en cada <br />
                                espacio.
                            </p>
                        </div>
                        <img
                            src={beginning2}
                            alt="Beginning"
                            onClick={() => {
                                imageViewerHandler(1);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="image-viewer" ref={imageViewer}>
                <div>
                    <div
                        ref={imageViewerPrev}
                        className="image-slide-nav image-slide-prev"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="58.81"
                            height="58.81"
                            viewBox="0 0 58.81 58.81"
                        >
                            <g
                                id="Group_1721"
                                data-name="Group 1721"
                                transform="translate(54.81 54.81) rotate(180)"
                            >
                                <path
                                    id="Path_1334"
                                    data-name="Path 1334"
                                    d="M0,35.928S26.934,9.211,35.928,0"
                                    transform="translate(25.405 0) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1335"
                                    data-name="Path 1335"
                                    d="M0,0,25.542.056"
                                    transform="translate(33.469 7.304) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1336"
                                    data-name="Path 1336"
                                    d="M0,0,25.431.056"
                                    transform="translate(33.469 43.347) rotate(-45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                            </g>
                        </svg>
                    </div>
                    <div
                        ref={imageViewerNext}
                        className="image-slide-nav image-slide-next"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="62.353"
                            height="62.353"
                            viewBox="0 0 62.353 62.353"
                        >
                            <g
                                id="Group_1722"
                                data-name="Group 1722"
                                transform="translate(-503.205 -10479.103)"
                            >
                                <path
                                    id="Path_1334"
                                    data-name="Path 1334"
                                    d="M0,38.433S28.812,9.853,38.433,0"
                                    transform="translate(534.381 10483.103) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1335"
                                    data-name="Path 1335"
                                    d="M0,0,27.323.06"
                                    transform="translate(543.008 10490.916) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1336"
                                    data-name="Path 1336"
                                    d="M0,0,27.2.06"
                                    transform="translate(543.007 10529.473) rotate(-45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="image-viewer-slider">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation={{
                            prevEl: imageViewerPrev.current,
                            nextEl: imageViewerNext.current,
                        }}
                        onInit={(swiper) => {
                            setSwiper(swiper);
                            swiper.params.navigation.prevEl =
                                imageViewerPrev.current;
                            swiper.params.navigation.nextEl =
                                imageViewerNext.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                    >
                        <SwiperSlide>
                            <img src={beginningDT} alt="Beginning" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={beginning2} alt="Beginning" />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="close" onClick={closeImageViewer}>
                    &#10005;
                </div>
            </div>
        </section>
    );
}

export default Beginning;
