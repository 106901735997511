import React from "react";

function Chat() {
    return (
        <div className="chat container">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="84.662"
                height="84.662"
                viewBox="0 0 84.662 84.662"
            >
                <g
                    id="Group_1518"
                    data-name="Group 1518"
                    transform="translate(-922.669 -1495.668)"
                >
                    <g id="Group_1494" data-name="Group 1494">
                        <path
                            id="Path_1384"
                            data-name="Path 1384"
                            d="M308.742,265.365a39.141,39.141,0,0,0,19.017,5.111,39.331,39.331,0,1,0-38.942-38.982,39.148,39.148,0,0,0,4.6,18.127,3.691,3.691,0,0,1,.3,2.7l-4.208,15.591a1.45,1.45,0,0,0,1.84,1.758l14.434-4.611A3.705,3.705,0,0,1,308.742,265.365Z"
                            transform="translate(636.853 1306.853)"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="3"
                        />
                        <path
                            id="Path_1385"
                            data-name="Path 1385"
                            d="M-3700.135-12447h34.3"
                            transform="translate(4648.245 13981)"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="3"
                        />
                        <path
                            id="Path_1386"
                            data-name="Path 1386"
                            d="M-3700.135-12447h17.89"
                            transform="translate(4648.245 13995)"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="3"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Chat;
