const vacancies = [
    {
        job: "Administrador general",
        location: "Medellín - 2022",
    },
    {
        job: "Constructor",
        location: "Medellín - 2022",
    },
    {
        job: "Arquitecto junior",
        location: "Medellín - 2022",
    },
    {
        job: "Desarrollador de negocios",
        location: "Medellín - 2022",
    },
    {
        job: "Administrador de proyectos",
        location: "Medellín - 2022",
    },
];

export default vacancies;
