import React from "react";
import { useParams } from "react-router-dom";
import Amenities from "../components/Amenities";
import PropertyHero from "../components/PropertyHero";
import Beginning from "../components/Beginning";
import Contact from "../components/Contact";
import CreativeHistory from "../components/CreativeHistory";
import Footer from "../components/Footer";
import FooterHero from "../components/FooterHero";
import Manifest from "../components/Manifest";
import RelevantData from "../components/RelevantData";
import TheChange from "../components/TheChange";
import bgImage from "../assets/images/FooterHero/property.jpg";
import Navbar from "../components/Navbar";
import Comfort from "../components/Comfort";
import Location from "../components/Location";
import We from "../components/We";
import ProjectScroller from "../components/ProjectScroller";
import Chat from "../components/Chat";
import properties from "../data/properties";
import properties_2 from "../data/properties_2";

function PropertyPage() {
    const { id } = useParams();
    let currentProperty;
    let indexOfProperty = 0;

    if (properties.some((value) => value.propertyImg === id)) {
        indexOfProperty = properties.findIndex((obj) => obj.propertyImg === id);
        currentProperty = properties;
    } else {
        indexOfProperty = properties_2.findIndex(
            (obj) => obj.propertyImg === id
        );
        currentProperty = properties_2;
    }

    return (
        <div className="propertypage">
            <Navbar />
            <PropertyHero
                propertyName={currentProperty[indexOfProperty].propertyName}
                propertyImg={currentProperty[indexOfProperty].propertyImg}
                indexOfProperty={indexOfProperty}
            />
            <We
                text={
                    "La forma de vivir y habitar un lugar ha ido cambiando con el paso de los años. Los millennials transformaron los espacios tradicionales."
                }
            />
            <ProjectScroller />
            <RelevantData />
            <CreativeHistory />
            <Beginning />
            <TheChange />
            <Comfort />
            <Location />
            <Amenities />
            <Manifest />
            <Contact
                title={["invierte en este", <br />, "o en futuros"]}
                coloredTitle={"proyectos"}
            />
            <FooterHero
                bgImage={bgImage}
                title={"SIGUIENTE PROYECTO"}
                heading={"living hotel"}
                text={"Alojamiento extraordinario en un lugar increíble"}
                link={""}
            />
            <Footer />
            <Chat />
        </div>
    );
}

export default PropertyPage;
