import React from "react";
import manifiestImg from "../assets/images/manifiest.jpg";

function Manifest() {
    return (
        <section className="manifest-section">
            <div className="container">
                <div className="manifest-content">
                    <h2 className="colored-text">Manifiesto</h2>
                    <div className="manifest">
                        <img src={manifiestImg} alt="Manifest" />
                        <p className="para-f2">
                            Donec nec nunc eros. Nulla sit amet ornare lacus.
                            Curabitur rhoncus sagittis justo eget imperdiet.
                            Cras lacinia suscipit ante ac auctor. Cras mollis
                            sceleris que bibendum.
                        </p>

                        <p className="para-f2">
                            Vestibulum consectetur ornare purus non dictum.
                            Praesent ornare gravida odio, ac interdum est. Proin
                            ut nibh at mauris lobortis pellentesque. Quisque
                            varius tristique mi et mollis. Aenean porta quis
                            lectus ac lacinia.
                        </p>
                        <p className="para-f2">
                            Suspendisse id posuere quam. Praesent dolor ex,
                            pretium ut sapien in, viverra condimentum turpis.
                            Mauris erat nisi, scelerisque id convallis
                            dignissim, interdum vitae nisl. Praesent suscipit
                            cursus ligula, et ullamcorper quam placerat vitae.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Manifest;
