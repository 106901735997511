import React from "react";
import Hero from "../components/Hero";
import heroImage from "../assets/images/Hero/carrera.jpg";
import We from "../components/We";
import Vacancies from "../components/Vacancies";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import SlidingLogo from "../components/SlidingLogo";
import Navbar from "../components/Navbar";
import Chat from "../components/Chat";

function CarreraPage() {
    return (
        <div className="carrerapage">
            <SlidingLogo />
            <Navbar />
            <Hero
                heroImage={heroImage}
                heroTexts={"Haz parte de nuestra"}
                heroColoredTexts={"familia."}
            />
            <We
                heading={"Carrera"}
                text={
                    "Las personas son lo más  ellas son el corazón de todo lo que hacemos. Bienvenidos a la familia DrimStay es momento de crecer juntos."
                }
            />
            <Vacancies />
            <Contact title="ENCUENTRA" coloredTitle="TU LUGAR." />
            <Footer />
            <Chat />
        </div>
    );
}

export default CarreraPage;
