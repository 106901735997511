import React, { useState, useRef } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import partner1 from "../assets/images/partner/partner-1.svg";
import partner2 from "../assets/images/partner/partner-2.svg";
import partner3 from "../assets/images/partner/partner-3.svg";
import partner4 from "../assets/images/partner/partner-4.svg";
import partner5 from "../assets/images/partner/partner-5.svg";
import partner6 from "../assets/images/partner/partner-6.svg";
import partner7 from "../assets/images/partner/partner-7.svg";
import partner8 from "../assets/images/partner/partner-8.svg";
import slide1 from "../assets/images/partner/slide-1.jpg";
import slide2 from "../assets/images/partner/slide-2.jpg";
import slide3 from "../assets/images/partner/slide-3.jpg";
import slide4 from "../assets/images/partner/slide-4.jpg";

function Partner() {
    const [activeSlide, setActiveSlide] = useState();

    const partnerNext = useRef();
    const partnerPrev = useRef();

    return (
        <section className="partner-section">
            <div className="container">
                <div className="partner-content">
                    <div>
                        <h2>
                            NUESTROS <br />
                            <span className="colored-text">SOCIOS</span>
                        </h2>
                        {activeSlide === 0 && (
                            <div className="partner-container">
                                <div className="partner">
                                    <img src={partner1} alt="Partner" />
                                </div>
                                <div className="partner">
                                    <img src={partner2} alt="Partner" />
                                </div>
                                <div className="partner">
                                    <img src={partner3} alt="Partner" />
                                </div>
                            </div>
                        )}
                        {activeSlide === 1 && (
                            <div className="partner-container">
                                <div className="partner">
                                    <img src={partner6} alt="Partner" />
                                </div>
                                <div className="partner">
                                    <img src={partner4} alt="Partner" />
                                </div>
                                <div className="partner">
                                    <img src={partner5} alt="Partner" />
                                </div>
                            </div>
                        )}
                        {activeSlide === 2 && (
                            <div className="partner-container">
                                <div
                                    className="partner"
                                    style={{ maxWidth: "80px" }}
                                >
                                    <img src={partner7} alt="Partner" />
                                </div>
                            </div>
                        )}
                        {activeSlide === 3 && (
                            <div className="partner-container">
                                <div
                                    className="partner"
                                    style={{ maxWidth: "100px" }}
                                >
                                    <img src={partner8} alt="Partner" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="partner-slider">
                        <div>
                            <div>
                                <div
                                    ref={partnerPrev}
                                    className="partner-slide-nav partner-slide-prev"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="58.81"
                                        height="58.81"
                                        viewBox="0 0 58.81 58.81"
                                    >
                                        <g
                                            id="Group_1721"
                                            data-name="Group 1721"
                                            transform="translate(54.81 54.81) rotate(180)"
                                        >
                                            <path
                                                id="Path_1334"
                                                data-name="Path 1334"
                                                d="M0,35.928S26.934,9.211,35.928,0"
                                                transform="translate(25.405 0) rotate(45)"
                                                fill="none"
                                                stroke="#a88e68"
                                                strokeLinecap="round"
                                                strokeWidth="4"
                                            />
                                            <path
                                                id="Path_1335"
                                                data-name="Path 1335"
                                                d="M0,0,25.542.056"
                                                transform="translate(33.469 7.304) rotate(45)"
                                                fill="none"
                                                stroke="#a88e68"
                                                strokeLinecap="round"
                                                strokeWidth="4"
                                            />
                                            <path
                                                id="Path_1336"
                                                data-name="Path 1336"
                                                d="M0,0,25.431.056"
                                                transform="translate(33.469 43.347) rotate(-45)"
                                                fill="none"
                                                stroke="#a88e68"
                                                strokeLinecap="round"
                                                strokeWidth="4"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <div
                                    ref={partnerNext}
                                    className="partner-slide-nav partner-slide-next"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="62.353"
                                        height="62.353"
                                        viewBox="0 0 62.353 62.353"
                                    >
                                        <g
                                            id="Group_1722"
                                            data-name="Group 1722"
                                            transform="translate(-503.205 -10479.103)"
                                        >
                                            <path
                                                id="Path_1334"
                                                data-name="Path 1334"
                                                d="M0,38.433S28.812,9.853,38.433,0"
                                                transform="translate(534.381 10483.103) rotate(45)"
                                                fill="none"
                                                stroke="#a88e68"
                                                strokeLinecap="round"
                                                strokeWidth="4"
                                            />
                                            <path
                                                id="Path_1335"
                                                data-name="Path 1335"
                                                d="M0,0,27.323.06"
                                                transform="translate(543.008 10490.916) rotate(45)"
                                                fill="none"
                                                stroke="#a88e68"
                                                strokeLinecap="round"
                                                strokeWidth="4"
                                            />
                                            <path
                                                id="Path_1336"
                                                data-name="Path 1336"
                                                d="M0,0,27.2.06"
                                                transform="translate(543.007 10529.473) rotate(-45)"
                                                fill="none"
                                                stroke="#a88e68"
                                                strokeLinecap="round"
                                                strokeWidth="4"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={15}
                            slidesPerView={1.1}
                            navigation={{
                                prevEl: partnerPrev.current,
                                nextEl: partnerNext.current,
                            }}
                            onInit={(swiper) => {
                                setActiveSlide(swiper.activeIndex);
                                swiper.params.navigation.prevEl =
                                    partnerPrev.current;
                                swiper.params.navigation.nextEl =
                                    partnerNext.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            onActiveIndexChange={(swiper) => {
                                setActiveSlide(swiper.activeIndex);
                            }}
                            breakpoints={{
                                640: {
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <div className="partner-slide">
                                    <h5>inmobiliarios</h5>
                                    <img src={slide1} alt="inmobiliarios" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="partner-slide">
                                    <h5>ARQUITECTURA</h5>
                                    <img src={slide2} alt="ARQUITECTURA" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="partner-slide">
                                    <h5>Interiorismo</h5>
                                    <img src={slide3} alt="Interiorismo" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="partner-slide">
                                    <h5>fiduciaria</h5>
                                    <img src={slide4} alt="fiduciaria" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Partner;
