import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loadingVideo from "../assets/videos/loading.mp4";
import heroVideo from "../assets/videos/hero.mp4";

function Loading() {
    let navigate = useNavigate();

    useEffect(() => {
        const loadingVideo = document.querySelector(".loading-video");

        const gotoHome = () => {
            navigate("/home", { replace: true });
        };

        const playVideo = () => {
            if (loadingVideo.readyState >= 3) {
                loadingVideo.pause();
                loadingVideo.playbackRate = 0.5;
                loadingVideo.play();
            }
        };

        loadingVideo.addEventListener("loadeddata", playVideo);
        loadingVideo.addEventListener("ended", gotoHome);

        return () => {
            loadingVideo.removeEventListener("loadeddata", playVideo);
            loadingVideo.removeEventListener("ended", gotoHome);
        };
    }, [navigate]);

    return (
        <section className="loading-section">
            <div className="container">
                <div className="loading-content">
                    <div className="loading-logo">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="284.428"
                            height="126.564"
                            viewBox="0 0 284.428 126.564"
                        >
                            <g
                                id="Group_1202"
                                data-name="Group 1202"
                                transform="translate(-0.001)"
                            >
                                <g
                                    id="Group_1191"
                                    data-name="Group 1191"
                                    transform="translate(128.921 0)"
                                >
                                    <g
                                        id="Group_1189"
                                        data-name="Group 1189"
                                        transform="translate(0 3.968)"
                                    >
                                        <path
                                            id="Path_1302"
                                            data-name="Path 1302"
                                            d="M-545.6,2946.537a.561.561,0,0,1-.269-.068l-7.37-3.968a.567.567,0,0,1-.3-.5v-68.6a.568.568,0,0,1,.567-.567.568.568,0,0,1,.567.567v68.259l6.236,3.358v-60a.568.568,0,0,1,.285-.492.567.567,0,0,1,.569,0l19.842,11.621a.568.568,0,0,1,.28.49v22.676a.569.569,0,0,1-.3.5l-13.367,7.11a.566.566,0,0,1-.767-.234.567.567,0,0,1,.234-.767l13.066-6.951v-22.011l-18.708-10.958v59.955a.565.565,0,0,1-.276.486A.567.567,0,0,1-545.6,2946.537Z"
                                            transform="translate(553.537 -2872.837)"
                                            fill="#a88e69"
                                        />
                                    </g>
                                    <g
                                        id="Group_1190"
                                        data-name="Group 1190"
                                        transform="translate(0.062)"
                                    >
                                        <path
                                            id="Path_1303"
                                            data-name="Path 1303"
                                            d="M-545.555,2941.512a.567.567,0,0,1-.49-.281.567.567,0,0,1,.2-.775l32.316-18.828v-37.615l-32.5-18.945-5.674,3.31,32.507,18.787a.567.567,0,0,1,.284.491V2917.7a.566.566,0,0,1-.281.489l-19.842,11.622a.565.565,0,0,1-.568,0,.566.566,0,0,1-.285-.492v-45.637a.567.567,0,0,1,.567-.567.567.567,0,0,1,.567.567v44.648l18.708-10.958v-29.4l-33.069-19.111a.568.568,0,0,1-.283-.49.569.569,0,0,1,.281-.491l6.8-3.968a.567.567,0,0,1,.571,0l33.069,19.275a.566.566,0,0,1,.281.49v38.267a.566.566,0,0,1-.282.49l-32.6,18.992A.564.564,0,0,1-545.555,2941.512Z"
                                            transform="translate(553.397 -2863.844)"
                                            fill="#a88e69"
                                        />
                                    </g>
                                </g>
                                <g
                                    id="Group_1192"
                                    data-name="Group 1192"
                                    transform="translate(0.001 99.325)"
                                >
                                    <path
                                        id="Path_1304"
                                        data-name="Path 1304"
                                        d="M-845.713,3116.29v-26.483h8.134c9.155,0,15.133,4.956,15.133,13.014,0,8.248-6.091,13.469-14.981,13.469Zm7.718-26.067h-4.2v25.65h4.351c7.907,0,11.69-4.767,11.69-13.052C-826.154,3094.8-829.937,3090.224-838,3090.224Z"
                                        transform="translate(845.713 -3089.429)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Path_1305"
                                        data-name="Path 1305"
                                        d="M-753.537,3116.29h-3.518v-26.483H-746.8c5.523,0,10.215,1.929,10.215,7.036,0,5.032-4.881,6.961-8.323,7.037v.075c3.48.265,5.524,1.627,6.507,4.426l1.475,4.2a9.367,9.367,0,0,0,2.043,3.708h-3.9a12.873,12.873,0,0,1-1.589-3.444l-1.437-4.085c-1.1-3.253-2.61-4.691-6.431-4.691h-5.3Zm0-12.637h6.053c4.01,0,7.15-1.626,7.15-6.733,0-4.691-2.837-6.7-7.112-6.7h-6.091Z"
                                        transform="translate(796.175 -3089.429)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Path_1306"
                                        data-name="Path 1306"
                                        d="M-667.2,3116.29h-3.518v-26.483h3.518Z"
                                        transform="translate(747.931 -3089.429)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Path_1307"
                                        data-name="Path 1307"
                                        d="M-609.614,3116.668h-.151l-11.917-26.18v25.8h-.416v-26.483h3.859l10.1,22.169,8.966-22.169h3.594v26.483h-3.443v-25.8Z"
                                        transform="translate(720.766 -3089.429)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Path_1308"
                                        data-name="Path 1308"
                                        d="M-469.508,3104.272l-3.821-.984c-4.464-1.134-7.377-2.912-7.377-7,0-4.237,3.972-7.34,9.079-7.34,4.5,0,7.982,2.082,9.534,5.259l-.379.264c-1.475-3.177-4.88-5.145-9.117-5.145-3.9,0-6.772,2.081-6.772,5.373,0,3.481,2.686,4.426,6.128,5.334l3.67.983c4.426,1.135,7.415,3.064,7.415,7.113,0,5.183-4.653,8.058-9.723,8.058-4.956,0-9.193-2.308-10.782-6.2l.378-.227c1.551,3.859,5.674,6.053,10.328,6.053,4.729,0,7.378-2.611,7.378-5.9C-463.569,3106.428-466.217,3105.066-469.508,3104.272Z"
                                        transform="translate(642.291 -3088.949)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Path_1309"
                                        data-name="Path 1309"
                                        d="M-398.32,3090.224h-10.366v-.416h24.212v.416h-10.366v26.067h-3.48Z"
                                        transform="translate(601.52 -3089.429)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Path_1310"
                                        data-name="Path 1310"
                                        d="M-314.835,3106.844h-12.258l-3.972,8.966h-.454l11.917-26.861h.151L-307,3115.81h-3.708Zm-6.242-13.582-5.827,13.165h11.879Z"
                                        transform="translate(558.402 -3088.949)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Path_1311"
                                        data-name="Path 1311"
                                        d="M-230.362,3089.808h.417l-9.61,15.435v11.048h-3.48v-10.972l-9.685-15.511h3.9l9.117,14.867Z"
                                        transform="translate(514.373 -3089.429)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>

                    <video
                        className="loading-video"
                        autoPlay={true}
                        src={loadingVideo}
                        muted={true}
                        playsInline
                    ></video>
                    <p className="colored-text">
                        LIVE THE NEw <br />
                        HOme EXPERIENCE
                    </p>
                </div>

                <video
                    src={heroVideo}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline
                    style={{
                        opacity: "0",
                        position: "absolute",
                        width: "0",
                        height: "0",
                    }}
                ></video>
            </div>
        </section>
    );
}

export default Loading;
