import React, { useState, useRef } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import slide1 from "../assets/images/innovation/slide-1.jpg";
import slide2 from "../assets/images/innovation/slide-2.jpg";
import slide3 from "../assets/images/innovation/slide-3.jpg";
import slide4 from "../assets/images/innovation/slide-4.jpg";

function TheChange() {
    const [swiper, setSwiper] = useState();
    const imageViewer = useRef();
    const imageViewerNext = useRef();
    const imageViewerPrev = useRef();
    const [totalSlides, setTotalSlides] = useState();
    const [activeSlide, setActiveSlide] = useState();

    const theChangeNext = useRef();
    const theChangePrev = useRef();

    const imageViewerHandler = (imageIndex) => {
        imageViewer.current.style.display = "flex";
        document.body.style.overflow = "hidden";
        swiper.slideTo(imageIndex);
    };

    const closeImageViewer = () => {
        imageViewer.current.style.display = "none";
        document.body.style.overflow = "auto";
    };

    return (
        <section className="the-change-section">
            <div className="container">
                <div className="the-change-content">
                    <div>
                        <h5>EL CAMBIO</h5>
                        <h2>
                            verdadera <br />
                            <span className="colored-text">innovación</span>
                        </h2>
                        <p className="para-f2">
                            COLIIV es un lugar multifuncional, con el uso
                            inteligente del espacio para suplir las necesidades
                            modernas.
                        </p>
                    </div>
                    <div className="the-change-slider">
                        <div>
                            <div
                                ref={theChangePrev}
                                className="the-change-slide-nav the-change-slide-next"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="58.81"
                                    height="58.81"
                                    viewBox="0 0 58.81 58.81"
                                >
                                    <g
                                        id="Group_1721"
                                        data-name="Group 1721"
                                        transform="translate(54.81 54.81) rotate(180)"
                                    >
                                        <path
                                            id="Path_1334"
                                            data-name="Path 1334"
                                            d="M0,35.928S26.934,9.211,35.928,0"
                                            transform="translate(25.405 0) rotate(45)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1335"
                                            data-name="Path 1335"
                                            d="M0,0,25.542.056"
                                            transform="translate(33.469 7.304) rotate(45)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1336"
                                            data-name="Path 1336"
                                            d="M0,0,25.431.056"
                                            transform="translate(33.469 43.347) rotate(-45)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <div
                                ref={theChangeNext}
                                className="the-change-slide-nav the-change-slide-prev"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="62.353"
                                    height="62.353"
                                    viewBox="0 0 62.353 62.353"
                                >
                                    <g
                                        id="Group_1722"
                                        data-name="Group 1722"
                                        transform="translate(-503.205 -10479.103)"
                                    >
                                        <path
                                            id="Path_1334"
                                            data-name="Path 1334"
                                            d="M0,38.433S28.812,9.853,38.433,0"
                                            transform="translate(534.381 10483.103) rotate(45)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1335"
                                            data-name="Path 1335"
                                            d="M0,0,27.323.06"
                                            transform="translate(543.008 10490.916) rotate(45)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1336"
                                            data-name="Path 1336"
                                            d="M0,0,27.2.06"
                                            transform="translate(543.007 10529.473) rotate(-45)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={30}
                            slidesPerView={1.1}
                            navigation={{
                                prevEl: theChangePrev.current,
                                nextEl: theChangeNext.current,
                            }}
                            onInit={(swiper) => {
                                setActiveSlide(swiper.activeIndex);
                                setTotalSlides(swiper.slides.length);
                                swiper.params.navigation.prevEl =
                                    theChangePrev.current;
                                swiper.params.navigation.nextEl =
                                    theChangeNext.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            onActiveIndexChange={(swiper) => {
                                setActiveSlide(swiper.activeIndex);
                            }}
                        >
                            <SwiperSlide>
                                <div
                                    onClick={() => {
                                        imageViewerHandler(0);
                                    }}
                                    className="the-change-slide"
                                >
                                    <img src={slide1} alt="Innovation" />
                                    <div>
                                        <h2 className="colored-text">01</h2>
                                        <p className="para">
                                            Counter Lobby <br /> 24 horas
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div onClick={() => {
                                imageViewerHandler(1);
                            }} className="the-change-slide">
                                    <img src={slide2} alt="Innovation" />
                                    <div>
                                        <h2 className="colored-text">02</h2>{" "}
                                        <p className="para">
                                            Coworking <br /> con sala de juntas
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div onClick={() => {
                                imageViewerHandler(2);
                            }} className="the-change-slide">
                                    <img src={slide3} alt="Innovation" />
                                    <div>
                                        <h2 className="colored-text">03</h2>{" "}
                                        <p className="para">
                                            Zoom rooms <br />
                                            Alto aislamiento
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div onClick={() => {
                                imageViewerHandler(3);
                            }} className="the-change-slide">
                                    <img src={slide4} alt="Innovation" />
                                    <div>
                                        <h2 className="colored-text">04</h2>{" "}
                                        <p className="para">
                                            Counter Lobby <br /> 24 horas
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className="slide-no">
                            <span className="para-f2">
                                {activeSlide + 1 > 9
                                    ? activeSlide + 1
                                    : `0${activeSlide + 1}`}
                            </span>
                            <span className="divider"></span>
                            <span className="para-f2">
                                {totalSlides > 9
                                    ? totalSlides
                                    : `0${totalSlides}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-viewer" ref={imageViewer}>
                <div>
                    <div
                        ref={imageViewerPrev}
                        className="image-slide-nav image-slide-prev"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="58.81"
                            height="58.81"
                            viewBox="0 0 58.81 58.81"
                        >
                            <g
                                id="Group_1721"
                                data-name="Group 1721"
                                transform="translate(54.81 54.81) rotate(180)"
                            >
                                <path
                                    id="Path_1334"
                                    data-name="Path 1334"
                                    d="M0,35.928S26.934,9.211,35.928,0"
                                    transform="translate(25.405 0) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1335"
                                    data-name="Path 1335"
                                    d="M0,0,25.542.056"
                                    transform="translate(33.469 7.304) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1336"
                                    data-name="Path 1336"
                                    d="M0,0,25.431.056"
                                    transform="translate(33.469 43.347) rotate(-45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                            </g>
                        </svg>
                    </div>
                    <div
                        ref={imageViewerNext}
                        className="image-slide-nav image-slide-next"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="62.353"
                            height="62.353"
                            viewBox="0 0 62.353 62.353"
                        >
                            <g
                                id="Group_1722"
                                data-name="Group 1722"
                                transform="translate(-503.205 -10479.103)"
                            >
                                <path
                                    id="Path_1334"
                                    data-name="Path 1334"
                                    d="M0,38.433S28.812,9.853,38.433,0"
                                    transform="translate(534.381 10483.103) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1335"
                                    data-name="Path 1335"
                                    d="M0,0,27.323.06"
                                    transform="translate(543.008 10490.916) rotate(45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                                <path
                                    id="Path_1336"
                                    data-name="Path 1336"
                                    d="M0,0,27.2.06"
                                    transform="translate(543.007 10529.473) rotate(-45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="4"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="image-viewer-slider">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation={{
                            prevEl: imageViewerPrev.current,
                            nextEl: imageViewerNext.current,
                        }}
                        onInit={(swiper) => {
                            setSwiper(swiper);
                            swiper.params.navigation.prevEl =
                                imageViewerPrev.current;
                            swiper.params.navigation.nextEl =
                                imageViewerNext.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                    >
                        <SwiperSlide>
                            <img src={slide1} alt="Innovation" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide2} alt="Innovation" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide3} alt="Innovation" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide4} alt="Innovation" />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="close" onClick={closeImageViewer}>
                    &#10005;
                </div>
            </div>
        </section>
    );
}

export default TheChange;
