import React from "react";
import map from "../assets/images/map.png";
import mapBtnImage from "../assets/images/map-btn.png";

function Location() {
    return (
        <section className="location-section">
            <div className="container">
                <div className="location-content">
                    <h5>UBICACIÓN</h5>
                    <h2>
                        En un lugar <br />{" "}
                        <span className="colored-text">Increíble</span>
                    </h2>
                    <div className="location-map">
                        <div className="location">
                            <p>Coliv</p>
                            <p>
                                Cra. 29 # 1 - XY <br /> Medellín, Colombia{" "}
                                <br />
                                www.coliiv.com
                            </p>
                        </div>
                        <img src={map} alt="Map" />
                        <a
                            href="https://goo.gl/maps/rmyuoHFHKFMDzNbd7"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="map-link"
                        >
                            <img src={mapBtnImage} alt="map" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Location;
