import React from "react";
import vacancies from "../data/vacancies";

function Vacancies() {
    const renderVacancies = vacancies.map((vacancy, index) => (
        <div className="vacancies">
            <div className="vacancies-img">
                <img
                    src={require(`../assets/images/vacancies/${vacancy.job}.jpg`)}
                    alt="Vacancy"
                />
            </div>
            <div>
                <p className="para-f2">{vacancy.job}</p>
                <p className="para">{vacancy.location}</p>
            </div>
        </div>
    ));
    return (
        <section className="vacancies-section">
            <div className="container">
                <div className="vacancies-content">
                    <h5>Vacantes</h5>

                    <div className="vacancies-scroller">
                        <div className="vacancies-container">
                            {renderVacancies}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Vacancies;
