import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";

function Calender(props) {
    // const minimumDate = `Jan-01-2022`;
    return (
        <div className="calender-container">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    orientation="landscape"
                    openTo="day"
                    disablePast={false}
                    value={props.calenderDate}
                    // minDate={new Date(minimumDate)}
                    onChange={(newDate) => {
                        props.setCalenderDate(newDate);
                    }}
                    onMonthChange={(newMonth) => {
                        props.setCurrentMonth(newMonth);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
}

export default Calender;
