import React from "react";
import heroVideo from "../assets/videos/hero.mp4";

function MainHero() {
    return (
        <section className="main-hero-section">
            <div className="main-hero-content">
                <a href="#scroll">
                    <video
                        src={heroVideo}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline
                    ></video>
                </a>
                <div className="hero-scroll">
                    <div className="scroll-container">
                        <h5>Scroll</h5>
                    </div>
                    <div className="scroll">
                        <div></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MainHero;
