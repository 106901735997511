import React from "react";
import teamVideo from "../assets/videos/team.mp4";

function CreativeHistory() {
    return (
        <section className="creative-history-section">
            <div className="container">
                <div className="creative-history-content">
                    <h2>
                        Una historia <br />
                        <span className="colored-text">creativa</span>
                    </h2>
                    <video
                        src={teamVideo}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline
                    ></video>
                </div>
            </div>
        </section>
    );
}

export default CreativeHistory;
