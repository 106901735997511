import React from "react";

function Hero(props) {
    return (
        <section
            className="hero-section black-overlay light"
            style={{
                backgroundImage: `url(${props.heroImage})`
            }}
        >
            <div className="container">
                <div className="hero-content">
                    <h2>
                        {props.heroTexts} <br />
                        <span className="colored-text">
                            {props.heroColoredTexts}
                        </span>
                    </h2>
                </div>
            </div>
        </section>
    );
}

export default Hero;
