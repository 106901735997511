const teamData = [
    {
        teamName: "Nam Dictum",
        teamRole: "Fundador",
        teamDetail:
            "“Aenean tellus oiod, mattis vel justo sit amet, semper consectetur ex. Maecenas laoreet ante turpis. Morbi in congue odio. Duis viverra ipsum elit augue ut maximus”.",
    },
    {
        teamName: "Est Laoreet",
        teamRole: "Director",
        teamDetail:
            "“Aenean tellus oiod, mattis vel justo sit amet, semper consectetur ex. Maecenas laoreet ante turpis. Morbi in congue odio. Duis viverra ipsum elit augue ut maximus”.",
    },
    {
        teamName: "Nullam Eu",
        teamRole: "Arquitecto",
        teamDetail:
            "“Aenean tellus oiod, mattis vel justo sit amet, semper consectetur ex. Maecenas laoreet ante turpis. Morbi in congue odio. Duis viverra ipsum elit augue ut maximus”.",
    },
    {
        teamName: "Feugiat Lobortis",
        teamRole: "Ingeniera",
        teamDetail:
            "“Aenean tellus oiod, mattis vel justo sit amet, semper consectetur ex. Maecenas laoreet ante turpis. Morbi in congue odio. Duis viverra ipsum elit augue ut maximus”.",
    },
    {
        teamName: "Morbi Vehicula",
        teamRole: "Desarrolladora",
        teamDetail:
            "“Aenean tellus oiod, mattis vel justo sit amet, semper consectetur ex. Maecenas laoreet ante turpis. Morbi in congue odio. Duis viverra ipsum elit augue ut maximus”.",
    },
    {
        teamName: "Justo Mattis",
        teamRole: "Diseñador",
        teamDetail:
            "“Aenean tellus oiod, mattis vel justo sit amet, semper consectetur ex. Maecenas laoreet ante turpis. Morbi in congue odio. Duis viverra ipsum elit augue ut maximus”.",
    },
];

export default teamData;
