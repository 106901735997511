import React from "react";

function RelevantData() {
    return (
        <section className="relevant-data-section">
            <div className="container">
                <div className="relevant-data-content">
                    <h5>DATOS RELEVANTES</h5>

                    <div className="relevant-data-container desktop">
                        <div className="row">
                            <div className="relevant-data">
                                <h2 className="colored-text">40%</h2>
                                <p className="para-f2">Larga estadía</p>
                            </div>
                            <div className="relevant-data">
                                <h2 className="colored-text">10M</h2>
                                <p className="para-f2">Consectetur lectus</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="relevant-data">
                                <h2 className="colored-text">60%</h2>
                                <p className="para-f2">
                                    Mediana estadía <br />
                                    amoblado
                                </p>
                            </div>
                            <div className="relevant-data">
                                <h2 className="colored-text">325</h2>
                                <p className="para-f2">Faucibus eget</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="relevant-data">
                                <h2 className="colored-text">4.9</h2>
                                <p className="para-f2">Etiam convallis urna</p>
                            </div>
                            <div className="relevant-data">
                                <h2 className="colored-text">15</h2>
                                <p className="para-f2">Duis turpis</p>
                            </div>
                        </div>
                    </div>

                    <div className="relevant-data-container mobile">
                        <div className="row">
                            <div className="relevant-data">
                                <h2 className="colored-text">40%</h2>
                                <p className="para-f2">Larga estadía</p>
                            </div>
                            <div className="relevant-data">
                                <h2 className="colored-text">60%</h2>
                                <p className="para-f2">
                                    Mediana estadía <br />
                                    amoblado
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="relevant-data">
                                <h2 className="colored-text">4.9</h2>
                                <p className="para-f2">Etiam convallis urna</p>
                            </div>
                            <div className="relevant-data">
                                <h2 className="colored-text">10M</h2>
                                <p className="para-f2">Consectetur lectus</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="relevant-data">
                                <h2 className="colored-text">325</h2>
                                <p className="para-f2">Faucibus eget</p>
                            </div>
                            <div className="relevant-data">
                                <h2 className="colored-text">15</h2>
                                <p className="para-f2">Duis turpis</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RelevantData;
