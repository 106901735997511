import React from "react";
import { Link } from "react-router-dom";

function Project(props) {
    const createProperties = props.propertiesData.map((property, index) => (
        <div className="project" key={index}>
            <div className="project-header">
                <div className="project-img">
                    <Link to={`/${property.propertyImg}`}>
                        <picture>
                            <source
                                srcSet={require(`../assets/images/property/webp/${property.propertyImg}.webp`)}
                                type="image/webp"
                            />
                            <source
                                srcSet={require(`../assets/images/property/${property.propertyImg}.jpg`)}
                                type="image/jpeg"
                            />
                            <img
                                src={require(`../assets/images/property/${property.propertyImg}.jpg`)}
                                loading="lazy"
                                alt={property.propertyImg}
                            />
                        </picture>
                    </Link>
                </div>
                <div className="project-detail">
                    <div className="project-title-container">
                        <div>
                            <h3>{property.propertyName}</h3>
                            <p className="para">{property.propertyLocation}</p>
                        </div>
                        <div>
                            <h3>
                                {index + 1 > 9 ? index + 1 : `0${index + 1}.`}
                            </h3>
                            <p className="para">{property.propertyType}</p>
                        </div>
                    </div>
                    <p className="para-f2">{property.propertyDescription}</p>
                    <div className="project-feature">
                        <div className="row">
                            <div>
                                <img
                                    src={require(`../assets/images/property-spec/${property.propertySpecImg[0]}.svg`)}
                                    alt={property.propertySpec[0]}
                                />
                                <p className="para">
                                    {property.propertySpec[0]}
                                </p>
                            </div>
                            <div>
                                <img
                                    src={require(`../assets/images/property-spec/${property.propertySpecImg[1]}.svg`)}
                                    alt={property.propertySpec[1]}
                                />
                                <p className="para">
                                    {property.propertySpec[1]}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <img
                                    src={require(`../assets/images/property-spec/${property.propertySpecImg[2]}.svg`)}
                                    alt={property.propertySpec[2]}
                                />
                                <p className="para">
                                    {property.propertySpec[2]}
                                </p>
                            </div>
                            <div>
                                <img
                                    src={require(`../assets/images/property-spec/${property.propertySpecImg[3]}.svg`)}
                                    alt={property.propertySpec[3]}
                                />
                                <p className="para">
                                    {property.propertySpec[3]}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`project-footer ${
                    !props.isFooterButton && "completed"
                }`}
            >
                <div className="left">
                    {props.isProgress && (
                        <div>
                            <p className="para project-investment">
                                Inversión al
                            </p>
                            <div className="project-progress">
                                <div
                                    className="progress"
                                    style={{
                                        maxWidth: `${property.propertyProgress}%`,
                                    }}
                                ></div>
                                <p className="para-f2 colored-text">
                                    {property.propertyProgress}%
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="right">
                    <Link
                        to={`/${property.propertyImg}`}
                        className="para colored-text"
                    >
                        Más detalles
                    </Link>
                    {props.isFooterButton && (
                        <div>
                            <button className="btn">INVERTIR</button>
                            <a href="#contact" className="btn">
                                COMPARTIR
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    ));

    return (
        <section className="project-section">
            <div className="container">
                <div className="project-content">
                    <div className="project-container">{createProperties}</div>
                </div>
            </div>
        </section>
    );
}

export default Project;
