import React from "react";

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footer-content">
                    <ul className="social-list">
                        <li>
                            <a href="#">FB</a>
                        </li>
                        <li>
                            <a href="#">IG</a>
                        </li>
                    </ul>
                    <p>© 2021 DRIM STAY</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
