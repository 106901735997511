import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import fase4 from "../assets/images/the-project/04.jpg";
import fase5 from "../assets/images/the-project/05.jpg";
import fase6 from "../assets/images/the-project/06.jpg";
import fase7 from "../assets/images/the-project/07.jpg";
import fase8 from "../assets/images/the-project/08.jpg";

function ProjectScroller() {
    const currentActiveFase = 5;
    const [activeSlide, setActiveSlide] = useState(currentActiveFase - 1);
    const [generatedList, setGeneratedList] = useState();
    const swiperRef = useRef();

    useEffect(() => {
        swiperRef.current.swiper.slideTo(activeSlide);

        const generateList = () => {
            let list = [];
            for (let i = 0; i < 9; i++) {
                const faseValue = i + 1 + (activeSlide + 1 - 5);
                if (faseValue === currentActiveFase) {
                    list.push(
                        <li
                            className="active-fase"
                            onClick={() => setActiveSlide(faseValue - 1)}
                        >
                            <p className="para">
                                <span>Fase</span> 0{faseValue}
                            </p>
                        </li>
                    );
                } else if (faseValue > 0 && faseValue <= 8) {
                    list.push(
                        <li onClick={() => setActiveSlide(faseValue - 1)}>
                            <p className="para">
                                <span>Fase</span> 0{faseValue}
                            </p>
                        </li>
                    );
                } else {
                    list.push(
                        <li>
                            <p className="para"></p>
                        </li>
                    );
                }
            }

            return list;
        };

        setGeneratedList(generateList);
    }, [activeSlide]);

    return (
        <section className="project-scroller">
            <div className="container">
                <div className="project-scroller-content">
                    <h5>EL PROYECTO</h5>
                    <div>
                        <ul className="project-scroller-slider">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="69.729"
                                height="989.67"
                                viewBox="0 0 69.729 989.67"
                            >
                                <path
                                    id="Path_1337"
                                    data-name="Path 1337"
                                    d="M0,0S247.262,67.5,494.065,67.5,987.214,0,987.214,0"
                                    transform="translate(68.5 1.228) rotate(90)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    opacity="0.5"
                                />
                            </svg>
                            {generatedList}
                        </ul>
                        <div className="project-scroller-slides">
                            <h5
                                onClick={() =>
                                    setActiveSlide(currentActiveFase - 1)
                                }
                                className="ongoing-phase"
                            >
                                <span></span> <p>Fase en curso</p>
                            </h5>
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                allowTouchMove={false}
                                ref={swiperRef}
                            >
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>Fondeo inicial</h5>
                                        </div>
                                        <p className="para">
                                            Inicio de la recaudación de fondos
                                            para el proyecto en febrero 2021.
                                        </p>
                                        <img src={fase4} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>Fondeo inicial</h5>
                                        </div>
                                        <p className="para">
                                            Inicio de la recaudación de fondos
                                            para el proyecto en febrero 2021.
                                        </p>
                                        <img src={fase4} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>Fondeo inicial</h5>
                                        </div>
                                        <p className="para">
                                            Inicio de la recaudación de fondos
                                            para el proyecto en febrero 2021.
                                        </p>
                                        <img src={fase4} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>Fondeo inicial</h5>
                                        </div>
                                        <p className="para">
                                            Inicio de la recaudación de fondos
                                            para el proyecto en febrero 2021.
                                        </p>
                                        <img src={fase4} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>
                                                Permisos en{" "}
                                                <br className="mobile-text-break" />{" "}
                                                regla
                                            </h5>
                                        </div>
                                        <p className="para">
                                            Solicitud de licencias para
                                            construcción 20 de agosto de 2021.
                                        </p>
                                        <img src={fase5} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>Construcción</h5>
                                        </div>
                                        <p className="para">
                                            Inicio de obra 07 de noviembre de
                                            2021. Avance de la obra 20%.
                                        </p>
                                        <img src={fase6} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>Apertura</h5>
                                        </div>
                                        <p className="para">
                                            Apertura de Coliiv programada para
                                            marzo de 2023.
                                        </p>
                                        <img src={fase7} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="scroller-head">
                                            <h6>{`0${activeSlide + 1}.`}</h6>
                                            <h5>
                                                Estabilización{" "}
                                                <br className="mobile-text-break" />{" "}
                                                del activo
                                            </h5>
                                        </div>
                                        <p className="para">
                                            Proyección de estabilización para
                                            noviembre de 2023.
                                        </p>
                                        <img src={fase8} alt="Construcción" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectScroller;
