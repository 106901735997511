import React from "react";

function SlidingLogo() {
    return (
        <div className="sliding-logo">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="402.247"
                height="770.185"
                viewBox="0 0 402.247 770.185"
            >
                <g
                    id="D_home"
                    data-name="D home"
                    transform="translate(-674.248 -104.628)"
                >
                    <g
                        id="Group_1327"
                        data-name="Group 1327"
                        transform="translate(674.748 105.128)"
                    >
                        <path
                            id="Path_1338"
                            data-name="Path 1338"
                            d="M674.748,114.121V803.374L748.8,843.249V230.9L948.171,347.67V575.522l-134.307,71.45"
                            transform="translate(-674.748 -74.247)"
                            fill="none"
                            stroke="#a88e69"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                        <path
                            id="Path_1339"
                            data-name="Path 1339"
                            d="M747.979,874.129,1075.517,683.3V298.8L743.243,105.128,674.887,145l335.122,193.674v301.9L810.638,757.354V298.8"
                            transform="translate(-674.27 -105.128)"
                            fill="none"
                            stroke="#a88e69"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default SlidingLogo;
