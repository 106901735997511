const properties = [
    {
        propertyImg: "Coliiv",
        propertyName: "Coliiv",
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Vivienda flexible para nómadas digitales.",
        propertyType: "Construcción",
        propertySpecImg: [
            "Amenidades",
            "Apartamentos",
            "Vivienda",
            "Contratos",
        ],
        propertySpec: [
            ["123", <br />, "Habitaciones"],
            ["7", <br />, "Apartamentos"],
            ["Comodidad ", <br />, "real"],
            ["Excelente", <br />, "ubicación"],
        ],
        propertyProgress: 85,
    },
    {
        propertyImg: "Tau House",
        propertyName: "Tau House",
        propertyLocation: "Guatapé, Colombia",
        propertyDescription: "Lectus faucibus eget duis turpis.",
        propertyType: "Apertura",
        propertySpecImg: [
            "Habitaciones",
            "Amenidades",
            "Vivienda",
            "Naturaleza",
        ],
        propertySpec: [
            ["24", <br />, "Habitaciones"],
            ["6", <br />, "Amenidades"],
            ["Vivienda", <br />, "todo incluido"],
            ["Naturaleza", <br />, "pura"],
        ],
        propertyProgress: 100,
    },
    {
        propertyImg: "Beliiv",
        propertyName: "Beliiv",
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Etiam convallis urna lacus at consectetur",
        propertyType: "Diseño",
        propertySpecImg: [
            "Apartamentos",
            "Amenidades",
            "Vivienda",
            "Contratos",
        ],
        propertySpec: [
            ["46", <br />, "Apartamentos"],
            ["11", <br />, "Amenidades"],
            ["Vivienda", <br />, "todo incluido"],
            ["Sin", <br />, "contratos"],
        ],
        propertyProgress: 100,
    },
    {
        propertyImg: "Wake",
        propertyName: "Wake",
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Wake your life",
        propertyType: "Construcción",
        propertySpecImg: [
            "Apartamentos",
            "Habitaciones",
            "Amenidades",
            "Comodidad",
        ],
        propertySpec: [
            ["32", <br />, "Apartamentos"],
            ["38", <br />, "Habitaciones"],
            ["8", <br />, "Amenidades"],
            ["Comodidad", <br />, "real"],
        ],
        propertyProgress: 85,
    },
    {
        propertyImg: "Wake ii",
        propertyName: "Wake ii",
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Lectus faucibus eget duis turpis",
        propertyType: "Fondeo inicial",
        propertySpecImg: [
            "Apartamentos",
            "Amenidades",
            "Habitaciones",
            "Comodidad",
        ],
        propertySpec: [
            ["17 Pisos", <br />, "habitables"],
            ["8", <br />, "Amenidades"],
            ["Lectus luc", <br />, "feugiat"],
            ["Donec", <br />, "nec"],
        ],
        propertyProgress: 30,
    },
    {
        propertyImg: "Wake Well",
        propertyName: "Wake Well",
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Etiam convallis urna lacus at consectetur",
        propertyType: "Permisos en regla",
        propertySpecImg: [
            "Apartamentos",
            "Habitaciones",
            "Amenidades",
            "Ubicación",
        ],
        propertySpec: [
            ["15 Pisos", <br />, "habitables"],
            ["104", <br />, "Habitaciones"],
            ["6", <br />, "Amenidades"],
            ["Ubicación", <br />, "estratégica"],
        ],
        propertyProgress: 52,
    },
];

export default properties;
