import React from "react";
import teamData from "../data/teamData";

function Team() {
    const handleTeamDetails = (event) => {
        event.target.classList.toggle("open");
    };

    const generateteam = teamData.map((team) => (
        <div className="team">
            <div className="team-img">
                <picture>
                    <source
                        srcSet={require(`../assets/images/team/webp/${team.teamName}.webp`)}
                        type="image/webp"
                    />
                    <source
                        srcSet={require(`../assets/images/team/${team.teamName}.jpg`)}
                        type="image/jpeg"
                    />
                    <img
                        src={require(`../assets/images/team/${team.teamName}.jpg`)}
                        loading="lazy"
                        alt={team.teamName}
                    />
                </picture>
                <div
                    className="expand-team-detail"
                    onClick={(event) => handleTeamDetails(event)}
                >
                    &#43;
                </div>
                <div className="team-detail">
                    <p>{team.teamDetail}</p>
                </div>
            </div>
            <h6 className="colored-text">{team.teamName}</h6>
            <p className="para">{team.teamRole}</p>
        </div>
    ));

    return (
        <section className="team-section">
            <div className="container">
                <div className="team-content">
                    <p className="para-f2">EQUIPO</p>
                    <div className="team-container">{generateteam}</div>
                </div>
            </div>
        </section>
    );
}

export default Team;
