import React from "react";

function We(props) {
    return (
        <section className="we-section">
            <div className="container">
                <div className="we-content">
                    {props.heading ? <h5>{props.heading}</h5> : ""}
                    <p>{props.text}</p>
                    {props.image ? (
                        <div className="we-img-container">
                            <img src={props.image} alt="We" />{" "}
                            <p className="we-image-location">
                                Medellín, Colombia
                            </p>{" "}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </section>
    );
}

export default We;
