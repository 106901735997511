import React from "react";

function Stats() {
    return (
        <section className="stats-section">
            <div className="container">
                <div className="stats-content">
                    <div className="stats-header">
                        <h2>
                            Una historia increíble <br />
                            de desarrollos <br />
                            <span className="colored-text">inmobiliarios.</span>
                        </h2>
                    </div>
                    <div className="stats-container">
                        <div className="stat">
                            <h2 className="colored-text">210K+</h2>
                            <p className="para">Inversores activos</p>
                        </div>
                        <div className="stat">
                            <h2 className="colored-text">$7B+</h2>
                            <p className="para">
                                Valor de transacción de activos totales
                            </p>
                        </div>
                        <div className="stat">
                            <h2 className="colored-text">210K+</h2>
                            <p className="para">
                                Dividendos netos obtenidos por los inversores.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Stats;
