import React, { useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logos/steii.png";
import { useTranslation } from "react-i18next";

function Navbar() {
    const navRef = useRef();

    const { t, i18n } = useTranslation();

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const handleNav = () => {
        navRef.current.classList.toggle("open");
    };

    return (
        <header>
            <div className="container">
                <nav ref={navRef}>
                    <a className="logo" href="/">
                        <img src={logo} alt="Drim Stay" />
                    </a>
                    <div className="hamburger" onClick={handleNav}>
                        <span></span>
                        <span></span>
                        <span>&#10005;</span>
                    </div>

                    <div className="nav-content">
                        <ul className="nav-list">
                            <li>
                                <Link to="/">{t("Home")}</Link>
                            </li>
                            <li>
                                <Link to="/project">{t("Projects")}</Link>
                            </li>
                            <li>
                                <Link to="/team">{t("Team")}</Link>
                            </li>
                            <li>
                                <Link to="/event">{t("Events")}</Link>
                            </li>
                            <li className="small">
                                <Link to="/invest">{t("Why Invest")}</Link>
                            </li>
                            <li className="small">
                                <Link to="/career">{t("Career")}</Link>
                            </li>
                            <li className="divider"></li>
                            <li className="x-small">
                                <button
                                    className={
                                        i18n.language === "es"
                                            ? "current-language"
                                            : ""
                                    }
                                    onClick={() => changeLanguage("es")}
                                >
                                    SPA
                                </button>
                            </li>
                            <li className="x-small">
                                <button
                                    className={
                                        i18n.language === "en"
                                            ? "current-language"
                                            : ""
                                    }
                                    onClick={() => changeLanguage("en")}
                                >
                                    ENG
                                </button>
                            </li>
                        </ul>
                        <div className="nav-footer">
                            <div className="container">
                                <div className="footer-content">
                                    <ul className="social-list">
                                        <li>
                                            <a href="#">FB</a>
                                        </li>
                                        <li>
                                            <a href="#">IG</a>
                                        </li>
                                    </ul>
                                    <p>© 2021 DRIM STAY</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;
