import React, { useRef } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import serviceImage1 from "../assets/images/service/service-1.jpg";
import serviceImage1MB from "../assets/images/service/service-1-mb.jpg";
import serviceImageSmall1 from "../assets/images/service/service-1-small.jpg";
import serviceImage2 from "../assets/images/service/service-2.jpg";

import serviceImage2MB from "../assets/images/service/service-2-mb.jpg";
import serviceImageSmall2 from "../assets/images/service/service-2-small.jpg";
import serviceImage3 from "../assets/images/service/service-3.jpg";

import serviceImage3MB from "../assets/images/service/service-3-mb.jpg";
import serviceImageSmall3 from "../assets/images/service/service-3-small.jpg";
import serviceImage4 from "../assets/images/service/service-4.jpg";

import serviceImage4MB from "../assets/images/service/service-4-mb.jpg";
import serviceImageSmall4 from "../assets/images/service/service-4-small.jpg";

function Services() {
    const serviceNext = useRef();
    const servicePrev = useRef();
    return (
        <section className="services-section">
            <div className="container">
                <div className="services-content">
                    <h5>SERVICIOS</h5>
                    <div className="service-slider">
                        <div className="service-swiper-nav-container">
                            <div
                                ref={servicePrev}
                                className="service-slide-nav service-slide-prev"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="58.81"
                                    height="58.81"
                                    viewBox="0 0 58.81 58.81"
                                >
                                    <g
                                        id="Group_1721"
                                        data-name="Group 1721"
                                        transform="translate(54.81 54.81) rotate(180)"
                                    >
                                        <path
                                            id="Path_1334"
                                            data-name="Path 1334"
                                            d="M0,35.928S26.934,9.211,35.928,0"
                                            transform="translate(25.405 0) rotate(45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1335"
                                            data-name="Path 1335"
                                            d="M0,0,25.542.056"
                                            transform="translate(33.469 7.304) rotate(45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1336"
                                            data-name="Path 1336"
                                            d="M0,0,25.431.056"
                                            transform="translate(33.469 43.347) rotate(-45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <div
                                ref={serviceNext}
                                className="service-slide-nav service-slide-next"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="62.353"
                                    height="62.353"
                                    viewBox="0 0 62.353 62.353"
                                >
                                    <g
                                        id="Group_1722"
                                        data-name="Group 1722"
                                        transform="translate(-503.205 -10479.103)"
                                    >
                                        <path
                                            id="Path_1334"
                                            data-name="Path 1334"
                                            d="M0,38.433S28.812,9.853,38.433,0"
                                            transform="translate(534.381 10483.103) rotate(45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1335"
                                            data-name="Path 1335"
                                            d="M0,0,27.323.06"
                                            transform="translate(543.008 10490.916) rotate(45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                        <path
                                            id="Path_1336"
                                            data-name="Path 1336"
                                            d="M0,0,27.2.06"
                                            transform="translate(543.007 10529.473) rotate(-45)"
                                            fill="none"
                                            stroke="#a88e68"
                                            strokeLinecap="round"
                                            strokeWidth="4"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={0}
                            slidesPerView={1}
                            autoHeight={true}
                            navigation={{
                                prevEl: servicePrev.current,
                                nextEl: serviceNext.current,
                            }}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl =
                                    servicePrev.current;
                                swiper.params.navigation.nextEl =
                                    serviceNext.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            breakpoints={{
                                768: {
                                    slidesPerView: 1,
                                },
                                992: {
                                    slidesPerView: 1.01,
                                },
                                1400: {
                                    slidesPerView: 1.04,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <div className="service-slide">
                                    <div className="services-flex">
                                        <div>
                                            <h4>01</h4>
                                            <picture>
                                                <source
                                                    media="(min-width: 992px)"
                                                    srcSet={serviceImage1}
                                                />
                                                <img
                                                    src={serviceImage1MB}
                                                    alt="Service"
                                                />
                                            </picture>
                                            <h4>
                                                Estructuración <br /> de
                                                proyectos
                                            </h4>
                                            <img
                                                src={serviceImageSmall1}
                                                alt="Service"
                                            />
                                        </div>
                                        <div className="para">
                                            <h4>Estructuración de proyectos</h4>
                                            <p>
                                                Creación del proyecto desde su
                                                inicio, hasta obtener el
                                                consenso de voluntades necesario
                                                para el desarrollo del mismo.
                                                Comprende los siguientes
                                                análisis:
                                            </p>
                                        </div>
                                    </div>
                                    <div className="services-list">
                                        <div>
                                            <p className="para">
                                                <span className="services-back-text">
                                                    N
                                                </span>
                                                Análisis <br /> Normativo
                                            </p>
                                            <p className="para">
                                                se analiza el potencial del lote
                                                según el mejor y mayor uso de
                                                acuerdo a la normatividad
                                                vigente.
                                            </p>
                                        </div>
                                        <div>
                                            <p className="para">
                                                <span className="services-back-text">
                                                    T
                                                </span>
                                                Análisis <br /> Técnico
                                            </p>
                                            <p className="para">
                                                Se realizan estudios
                                                preliminares desde el punto de
                                                vista arquitectónico,
                                                estructural, suelos, entre
                                                otros.
                                            </p>
                                        </div>
                                        <div>
                                            <p className="para">
                                                <span className="services-back-text">
                                                    C
                                                </span>
                                                Análisis <br /> Comercial
                                            </p>
                                            <p className="para">
                                                Se estudia el mercado objetivo y
                                                se define el mejor producto.
                                            </p>
                                        </div>
                                        <div>
                                            <p className="para">
                                                <span className="services-back-text">
                                                    F
                                                </span>
                                                Análisis <br /> Financiero
                                            </p>
                                            <p className="para">
                                                Con todos los estudios
                                                anteriores y otros insumos
                                                adicionales, se analiza el
                                                proyecto financieramente por
                                                medio de una prefactibilidad,
                                                con en fin de determinar su
                                                viabilidad económica.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="service-slide">
                                    <div className="services-flex">
                                        <div>
                                            <h4>02</h4>
                                            <picture>
                                                <source
                                                    media="(min-width: 992px)"
                                                    srcSet={serviceImage2}
                                                />
                                                <img
                                                    src={serviceImage2MB}
                                                    alt="Service"
                                                />
                                            </picture>
                                            <h4>
                                                Gerencia <br /> de Proyectos
                                            </h4>
                                            <img
                                                src={serviceImageSmall2}
                                                alt="Service"
                                            />
                                        </div>
                                        <div className="para">
                                            <h4>Gerencia de Proyectos</h4>
                                            <p>
                                                Creación del proyecto desde su
                                                inicio, hasta obtener el
                                                consenso de voluntades necesario
                                                para el desarrollo del mismo.
                                                Comprende los siguientes
                                                análisis:
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="service-slide">
                                    <div className="services-flex">
                                        <div>
                                            <h4>03</h4>
                                            <picture>
                                                <source
                                                    media="(min-width: 992px)"
                                                    srcSet={serviceImage3}
                                                />
                                                <img
                                                    src={serviceImage3MB}
                                                    alt="Service"
                                                />
                                            </picture>
                                            <h4>
                                                Comercialización <br /> y ventas
                                            </h4>
                                            <img
                                                src={serviceImageSmall3}
                                                alt="Service"
                                            />
                                        </div>
                                        <div className="para">
                                            <h4>Comercialización y ventas</h4>
                                            <p>
                                                Son todas las gestiones
                                                necesarias para tener el cierre
                                                comercial del proyecto, entre
                                                las que están la definición del
                                                producto, la estrategia de
                                                precios, la coordinación de la
                                                fiducia, el desarrollo de
                                                estrategias de publicidad y
                                                mercadeo, la consecusión de los
                                                recursos para el desarrollo del
                                                proyecto, entre otros.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="service-slide">
                                    <div className="services-flex">
                                        <div>
                                            <h4>04</h4>
                                            <picture>
                                                <source
                                                    media="(min-width: 992px)"
                                                    srcSet={serviceImage4}
                                                />
                                                <img
                                                    src={serviceImage4MB}
                                                    alt="Service"
                                                />
                                            </picture>
                                            <h4>
                                                Consultoría <br /> de
                                                Inmobiliaria
                                            </h4>
                                            <img
                                                src={serviceImageSmall4}
                                                alt="Service"
                                            />
                                        </div>
                                        <div className="para">
                                            <h4>Consultoría Inmobiliaria</h4>
                                            <p>
                                                Creación del proyecto desde su
                                                inicio, hasta obtener el
                                                consenso de voluntades necesario
                                                para el desarrollo del mismo.
                                                Comprende los siguientes
                                                análisis:
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
