const properties_2 = [
    {
        propertyImg: "The click clack hotel",
        propertyName: ["The click ", <br />, <span>clack hotel</span>],
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Un espacio de diseño sofisticado y atrevido.",
        propertyType: "Abierto",
        propertySpecImg: [
            "Habitaciones",
            "Amenidades",
            "Comodidad",
            "Ubicación",
        ],
        propertySpec: [
            ["50", <br />, "Apartamentos"],
            ["9", <br />, "Amenidades"],
            ["Vivienda", <br />, "todo incluido"],
            ["Sin", <br />, "contratos"],
        ],
        propertyProgress: 85,
    },
    {
        propertyImg: "El Cielo",
        propertyName: "El Cielo",
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Experimenta con los cinco sentidos.",
        propertyType: "Abierto",
        propertySpecImg: [
            "Habitaciones",
            "Amenidades",
            "Restaurante",
            "Naturaleza",
        ],
        propertySpec: [
            ["28", <br />, "Habitaciones"],
            ["5", <br />, "Amenidades"],
            ["Restaurante", <br />, "El Cielo"],
            ["Increíble ", <br />, "experiencia"],
        ],
        propertyProgress: 100,
    },
    {
        propertyImg: "El Tesoro Parque Comercial",
        propertyName: ["El Tesoro", <br />, <span>Parque Comercial</span>],
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Un espacio de diseño sofisticado y atrevido.",
        propertyType: "Abierto",
        propertySpecImg: ["comercio", "parqueo", "Comodidad", "Médica"],
        propertySpec: [
            ["+ 400 Tiendas y", <br />, "Locales"],
            ["+ 1700", <br />, "Parqueaderos"],
            ["Centro de", <br />, "eventos"],
            ["Torre Médica"],
        ],
        propertyProgress: 100,
    },
    {
        propertyImg: "JARDINES LLANOGRANDE PARQUE COMERCIAL",
        propertyName: [
            "JARDINES LLANOGRANDE ",
            <br />,
            <span>PARQUE COMERCIAL</span>,
        ],
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "El Life-Style Mall más importante.",
        propertyType: "Abierto",
        propertySpecImg: ["comercio", "Amenidades", "Pet", "Ubicación"],
        propertySpec: [
            ["+ 80 Espacios", <br />, "comerciales"],
            ["Bike", <br />, "Friendly"],
            ["Pet", <br />, "Friendly"],
            ["Ubicación ", <br />, "estratégica"],
        ],
        propertyProgress: 85,
    },
    {
        propertyImg: "NOVOTEL EL TESORO",
        propertyName: "NOVOTEL EL TESORO",
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Hacemos que cada momento importe",
        propertyType: "Abierto",
        propertySpecImg: ["Habitaciones", "Amenidades", "Comodidad", "Pet"],
        propertySpec: [
            ["200", <br />, "Habitaciones"],
            ["6", <br />, "Amenidades"],
            ["Comodidad", <br />, "real"],
            ["Pet", <br />, "Friendly"],
        ],
        propertyProgress: 30,
    },
    {
        propertyImg: "RÍO DEL ESTE PARQUE COMERCIAL",
        propertyName: ["RÍO DEL ESTE ", <br />, <span>PARQUE COMERCIAL</span>],
        propertyLocation: "Medellín, Colombia",
        propertyDescription: "Mall de Conveniencia.",
        propertyType: "Abierto",
        propertySpecImg: ["comercio", "parqueo", "Restaurante", "Ubicación"],
        propertySpec: [
            ["Servicios y", <br />, "comercio"],
            ["Zonas de", <br />, "parqueo"],
            ["Plazoleta de", <br />, "comidas"],
            ["Ubicación", <br />, "estratégica"],
        ],
        propertyProgress: 52,
    },
];

export default properties_2;
