import React from "react";

function FooterHero(props) {
    return (
        <section className="footer-hero-section">
            <div className="container">
                <div className="footer-hero-content">
                    <h5>{props.title}</h5>

                    <div
                        className="footer-hero-main black-overlay"
                        style={{
                            backgroundImage: `url(${props.bgImage})`,
                        }}
                    >
                        <h2>{props.heading}</h2>{" "}
                        <p className="para-f2">{props.text}</p>
                        <a href={props.link}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="62.353"
                                height="62.353"
                                viewBox="0 0 62.353 62.353"
                            >
                                <g
                                    data-name="Group 1694"
                                    transform="translate(4 4)"
                                >
                                    <path
                                        data-name="Path 1334"
                                        d="M0,38.433S28.812,9.853,38.433,0"
                                        transform="translate(27.176) rotate(45)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeWidth="4"
                                    />
                                    <path
                                        data-name="Path 1335"
                                        d="M0,0,27.323.06"
                                        transform="translate(35.802 7.813) rotate(45)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeWidth="4"
                                    />
                                    <path
                                        data-name="Path 1336"
                                        d="M0,0,27.2.06"
                                        transform="translate(35.802 46.37) rotate(-45)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeWidth="4"
                                    />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FooterHero;
