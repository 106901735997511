import React from "react";

function MainHead() {
    return (
        <section className="main-head-section" id="scroll">
            <div className="container">
                <div className="main-head-content">
                    <div className="main-head">
                        <h2>
                            Diseñamos y <br />
                            operamos activos <br />
                            inmobiliarios <br />
                            <span className="colored-text">únicos.</span>
                        </h2>
                    </div>
                    <h5 className="title-bottom-border">PROYECTOS</h5>
                </div>
            </div>
        </section>
    );
}

export default MainHead;
