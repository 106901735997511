import React, { useState, useEffect, useRef } from "react";
import { EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Calender from "../components/Calender";
import slide1 from "../assets/images/event-slides/slide-1.jpg";
import slide2 from "../assets/images/event-slides/slide-2.jpg";
import slide3 from "../assets/images/event-slides/slide-3.jpg";
import slide4 from "../assets/images/event-slides/slide-4.jpg";
import slide5 from "../assets/images/event-slides/slide-5.jpg";

function EventSlider(props) {
    const [eventSwitch, setEventSwitch] = useState("slider");
    const [eventSlide, setEventSlide] = useState(2);
    const [currentMonth, setCurrentMonth] = useState();
    const swiperRef = useRef();

    useEffect(() => {
        const clickedSlideSwitch = (index) => {
            setEventSlide(index);
            setTimeout(() => {
                setEventSwitch("slider");
            }, 100);
        };

        if (eventSwitch === "calendar") {
            props.eventDates.forEach((date, index) => {
                document
                    .querySelector(`button[aria-label="${date}"]`)
                    ?.addEventListener("click", () =>
                        clickedSlideSwitch(index)
                    );
            });
        } else if (eventSwitch === "list") {
            document
                .querySelectorAll(".event-list-container li")
                .forEach((list, index) => {
                    list?.addEventListener("click", () =>
                        clickedSlideSwitch(index)
                    );
                });
        }
    }, [eventSwitch, props.eventDates, currentMonth]);

    useEffect(() => {
        if (eventSwitch === "slider") {
            swiperRef.current.swiper.slideTo(eventSlide);
        }
    }, [eventSwitch]);

    return (
        <section className="upcoming-event-section">
            <div className="container">
                <div className="upcoming-event-content">
                    <h5>PRÓXIMOS EVENTOS</h5>
                    {eventSwitch === "slider" && (
                        <div className="upcoming-events-slider">
                            <Swiper
                                modules={[EffectCoverflow]}
                                effect={"coverflow"}
                                spaceBetween={60}
                                slidesPerView={1.5}
                                centeredSlides={true}
                                initialSlide={eventSlide}
                                ref={swiperRef}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 50,
                                    modifier: 1.2,
                                    slideShadows: false,
                                }}
                                onActiveIndexChange={(swiper) => {
                                    setEventSlide(swiper.activeIndex);
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="upcoming-event-slide">
                                        <h6>13 Enero 2022</h6>
                                        <img
                                            src={slide3}
                                            alt="Upcoming Event"
                                        />
                                        <p>
                                            Taller creación de <br /> contenidos
                                            digitales
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="upcoming-event-slide">
                                        <h6>17 Enero 2022</h6>
                                        <img
                                            src={slide4}
                                            alt="Upcoming Event"
                                        />
                                        <p>NARRATIVAS DIGITALES</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="upcoming-event-slide">
                                        <h6>22 Enero 2022</h6>
                                        <img
                                            src={slide1}
                                            alt="Upcoming Event"
                                        />
                                        <p>
                                            Taller creación de contenidos
                                            digitales
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="upcoming-event-slide">
                                        <h6>01 Febrero 2022</h6>
                                        <img
                                            src={slide5}
                                            alt="Upcoming Event"
                                        />
                                        <p>Curabitur metus augue rutrum</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="upcoming-event-slide">
                                        <h6>09 Febrero 2022</h6>
                                        <img
                                            src={slide2}
                                            alt="Upcoming Event"
                                        />
                                        <p>
                                            Cinco tendencias de <br /> productos
                                            digitales
                                        </p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    )}
                    {eventSwitch === "calendar" && (
                        <Calender
                            calenderDate={props.calenderDate}
                            setCalenderDate={props.setCalenderDate}
                            setCurrentMonth={setCurrentMonth}
                        />
                    )}

                    {eventSwitch === "list" && (
                        <ul className="event-list-container">
                            <li>
                                <span>
                                    13 <br /> Enero
                                </span>
                                <span>
                                    Taller creación de contenidos digitales
                                </span>
                            </li>
                            <li>
                                <span>
                                    17 <br /> Enero
                                </span>
                                <span>Narrativas digitales</span>
                            </li>
                            <li>
                                <span>
                                    22 <br /> Enero
                                </span>
                                <span>Maecenas laoreet ante turpis</span>
                            </li>
                            <li>
                                <span>
                                    01 <br /> Febrero
                                </span>
                                <span>Curabitur metus augue rutrum</span>
                            </li>
                            <li>
                                <span>
                                    09 <br /> Febrero
                                </span>
                                <span>
                                    Cinco tendencias de productos digitales
                                </span>
                            </li>
                        </ul>
                    )}

                    <div className="event-switch-container container">
                        <div
                            className={`event-calender-switch ${
                                eventSwitch === "calendar" ? "open" : ""
                            }`}
                            onClick={() => {
                                eventSwitch === "calendar"
                                    ? setEventSwitch("slider")
                                    : setEventSwitch("calendar");
                            }}
                        ></div>
                        <button
                            className="event-list-switch"
                            onClick={() => {
                                eventSwitch === "list"
                                    ? setEventSwitch("slider")
                                    : setEventSwitch("list");
                            }}
                        >
                            {["slider", "calendar"].includes(eventSwitch)
                                ? "VER LISTA"
                                : "VER IMAGEN"}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EventSlider;
