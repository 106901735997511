import React from "react";
import Hero from "../components/Hero";
import Risks from "../components/Risks";
import We from "../components/We";
import Team from "../components/Team";
import Footer from "../components/Footer";
import heroImage from "../assets/images/Hero/nosotros.jpg";
import weImage from "../assets/images/we.jpg";
import Navbar from "../components/Navbar";
import SlidingLogo from "../components/SlidingLogo";
import Services from "../components/Services";
import Chat from "../components/Chat";

function NosotrosPage() {
    return (
        <div className="teampage">
            <SlidingLogo />
            <Navbar />
            <Hero
                heroImage={heroImage}
                heroTexts={["Creamos realidades", <br />, "Que cambian"]}
                heroColoredTexts={"el futuro."}
            />
            <We
                heading={"NOSOTROS"}
                text={
                    "Desarrollar proyectos de ciudad que impactan positivamente el futuro de las personas."
                }
                image={weImage}
            />
            <Risks />
            <Services />
            <Team />
            <Footer />
            <Chat />
        </div>
    );
}

export default NosotrosPage;
