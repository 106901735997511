import React from "react";
import Loading from "../components/Loading";

function LoadingPage() {
    return (
        <div>
            <Loading />
        </div>
    );
}

export default LoadingPage;
