import React, { useEffect } from "react";

function PropertyHero(props) {
    useEffect(() => {
        const scroll = document.querySelector(".scroll");

        const hideScroll = () => {
            const scrollPos = window.pageYOffset;
            if (scrollPos > 80) {
                scroll.style.opacity = "0";
            } else {
                scroll.style.opacity = "1";
            }
        };
        window.addEventListener("scroll", hideScroll);

        return () => {
            window.removeEventListener("scroll", hideScroll);
        };
    }, []);
    return (
        <section
            // style={{
            //     backgroundImage: `url(${
            //         process.env.PUBLIC_URL +
            //         "/property/" +
            //         props.propertyImg.replace(/ /g, "%20") +
            //         ".jpg"
            //     })`,
            // }}
            className="property-hero-section black-overlay light"
        >
            <div className="container">
                <div className="property-hero-content">
                    <div>
                        <h2>{props.propertyName}</h2>
                        <p className="para-f2">
                            {props.indexOfProperty + 1 > 9
                                ? props.indexOfProperty + 1
                                : `0${props.indexOfProperty + 1}`}
                        </p>
                        <div className="scroll-container">
                            <h5>Scroll</h5>
                        </div>
                        <div className="scroll">
                            <div></div>
                        </div>
                    </div>
                    <h2>
                        El hogar para <br /> los Nómadas <br />
                        <span className="colored-text">Digitales.</span>
                    </h2>
                </div>
            </div>
        </section>
    );
}

export default PropertyHero;
