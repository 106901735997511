import React from "react";
import MainHero from "../components/MainHero";
import MainHead from "../components/MainHead";
import Project from "../components/Project";
import Stats from "../components/Stats";
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import Partner from "../components/Partner";
import Companies from "../components/Companies";
import Footer from "../components/Footer";
import heroImage from "../assets/images/Hero/home.jpg";
import Navbar from "../components/Navbar";
import Chat from "../components/Chat";
import propertiesData from "../data/properties";

function Home() {
    return (
        <div className="homepage">
            <Navbar />
            <MainHero />
            <MainHead />
            <Project
                propertiesData={propertiesData}
                isFooterButton={true}
                isProgress={true}
            />
            <Stats />
            <Companies />
            <Partner />
            <Hero
                heroImage={heroImage}
                heroTexts={["Recibe", <br />, "Detalles"]}
                heroColoredTexts={"Financieros"}
            />
            <Contact />
            <Footer />
            <Chat />
        </div>
    );
}

export default Home;
