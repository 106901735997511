import React, { useState } from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Project from "../components/Project";
import Chat from "../components/Chat";
import SlidingLogo from "../components/SlidingLogo";
import propertiesData from "../data/properties";
import propertiesData_2 from "../data/properties_2";
import heroImage from "../assets/images/Hero/project.jpg";

function ProjectPage() {
    const [optionSelected, setOptionSelected] = useState("royectos-activos");

    const switchComfortOption = (event) => {
        document
            .querySelectorAll(".option-selector-container > p ")
            .forEach((option) => {
                option.classList.remove("selected");
            });
        event.target.classList.add("selected");
        setOptionSelected(event.target.getAttribute("data-option"));
    };

    return (
        <div className="projectpage">
            <SlidingLogo />
            <Navbar />
            <Hero
                heroImage={heroImage}
                heroTexts={"nuestros"}
                heroColoredTexts={"proyectos."}
            />
            <div className="container">
                <div className="option-selector-container">
                    <p
                        className="para-f2 selected"
                        data-option="royectos-activos"
                        onClick={(event) => switchComfortOption(event)}
                    >
                        Proyectos <br className="mobile-text-break" /> Activos
                    </p>
                    <p
                        className="para-f2"
                        data-option="proyectos-terminados"
                        onClick={(event) => switchComfortOption(event)}
                    >
                        Proyectos <br className="mobile-text-break" />{" "}
                        Terminados
                    </p>
                </div>
            </div>

            {optionSelected === "royectos-activos" && (
                <Project
                    propertiesData={propertiesData}
                    isFooterButton={true}
                    isProgress={true}
                />
            )}

            {optionSelected === "proyectos-terminados" && (
                <Project
                    propertiesData={propertiesData_2}
                    isFooterButton={false}
                    isProgress={false}
                />
            )}

            {/* <Project propertiesData={propertiesData_2} /> */}

            <Contact title={"Recibe Detalles"} coloredTitle={"Financieros"} />
            <Footer />
            <Chat />
        </div>
    );
}

export default ProjectPage;
