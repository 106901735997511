import React, { useState, useRef } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import studioLoft from "../assets/images/comfort/studio-loft.jpg";
import studioLoft2 from "../assets/images/comfort/studio-loft-2.jpg";
import habitacion from "../assets/images/comfort/habitacion.jpg";
import mitchellImg from "../assets/images/comfort/mitchell.jpg";

function Comfort() {
    const [optionSelected, setOptionSelected] = useState("studio-loft");

    const comfortStudioLoftNext = useRef();
    const comfortStudioLoftPrev = useRef();
    const comfortHabitacion1Next = useRef();
    const comfortHabitacion1Prev = useRef();
    const comfortHabitacion2Next = useRef();
    const comfortHabitacion2Prev = useRef();

    const handleComfortPopup = () => {
        document
            .querySelector(".comfort-img-popup-btn")
            .classList.toggle("open");
    };

    const switchComfortOption = (event) => {
        document
            .querySelectorAll(".option-selector-container > p ")
            .forEach((option) => {
                option.classList.remove("selected");
            });
        event.target.classList.add("selected");
        setOptionSelected(event.target.getAttribute("data-option"));
    };

    return (
        <section className="comfort-section">
            <div className="container">
                <div className="comfort-content">
                    <h5>COMODIDAD</h5>
                    <h2>
                        Tres TIPOS DE ESPACIOS <br />
                        <span className="colored-text">PARA HABITAR</span>
                    </h2>

                    <div className="option-selector-container">
                        <p
                            className="para-f2 selected"
                            data-option="studio-loft"
                            onClick={(event) => switchComfortOption(event)}
                        >
                            Studio <br className="mobile-text-break" /> loft
                        </p>
                        <p
                            className="para-f2"
                            data-option="habitacion-1"
                            onClick={(event) => switchComfortOption(event)}
                        >
                            Habitación <br className="mobile-text-break" /> 1 –
                            1
                        </p>
                        <p
                            className="para-f2"
                            data-option="habitacion-2"
                            onClick={(event) => switchComfortOption(event)}
                        >
                            Habitación <br className="mobile-text-break" /> 2 –
                            2
                        </p>
                    </div>

                    <div className="comfort-options">
                        {optionSelected === "studio-loft" ? (
                            <div>
                                <div className="comfort-swiper-nav-container">
                                    <div
                                        ref={comfortStudioLoftPrev}
                                        className="comfort-slide-nav comfort-slide-prev"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="58.81"
                                            height="58.81"
                                            viewBox="0 0 58.81 58.81"
                                        >
                                            <g
                                                id="Group_1721"
                                                data-name="Group 1721"
                                                transform="translate(54.81 54.81) rotate(180)"
                                            >
                                                <path
                                                    id="Path_1334"
                                                    data-name="Path 1334"
                                                    d="M0,35.928S26.934,9.211,35.928,0"
                                                    transform="translate(25.405 0) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1335"
                                                    data-name="Path 1335"
                                                    d="M0,0,25.542.056"
                                                    transform="translate(33.469 7.304) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1336"
                                                    data-name="Path 1336"
                                                    d="M0,0,25.431.056"
                                                    transform="translate(33.469 43.347) rotate(-45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div
                                        ref={comfortStudioLoftNext}
                                        className="comfort-slide-nav comfort-slide-next"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="62.353"
                                            height="62.353"
                                            viewBox="0 0 62.353 62.353"
                                        >
                                            <g
                                                id="Group_1722"
                                                data-name="Group 1722"
                                                transform="translate(-503.205 -10479.103)"
                                            >
                                                <path
                                                    id="Path_1334"
                                                    data-name="Path 1334"
                                                    d="M0,38.433S28.812,9.853,38.433,0"
                                                    transform="translate(534.381 10483.103) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1335"
                                                    data-name="Path 1335"
                                                    d="M0,0,27.323.06"
                                                    transform="translate(543.008 10490.916) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1336"
                                                    data-name="Path 1336"
                                                    d="M0,0,27.2.06"
                                                    transform="translate(543.007 10529.473) rotate(-45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                </div>

                                <div
                                    className="comfort-img-popup-btn"
                                    onClick={handleComfortPopup}
                                ></div>

                                <div
                                    className="comfort-popup-container"
                                    onClick={handleComfortPopup}
                                >
                                    <div className="comfort-popup">
                                        <img src={mitchellImg} alt="Mitchell" />
                                        <p className="para">Madera lisa 5502</p>
                                    </div>
                                </div>

                                <div>
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        navigation={{
                                            prevEl: comfortStudioLoftPrev.current,
                                            nextEl: comfortStudioLoftNext.current,
                                        }}
                                        onInit={(swiper) => {
                                            swiper.params.navigation.prevEl =
                                                comfortStudioLoftPrev.current;
                                            swiper.params.navigation.nextEl =
                                                comfortStudioLoftNext.current;
                                            swiper.navigation.init();
                                            swiper.navigation.update();
                                        }}
                                    >
                                        <SwiperSlide>
                                            <img
                                                src={studioLoft}
                                                alt="Studio Loft"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={studioLoft}
                                                alt="Studio Loft"
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <p className="para-f2">Sala</p>
                            </div>
                        ) : optionSelected === "habitacionn-1" ? (
                            <div>
                                <div className="comfort-swiper-nav-container">
                                    <div
                                        ref={comfortHabitacion1Prev}
                                        className="comfort-slide-nav comfort-slide-prev"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="58.81"
                                            height="58.81"
                                            viewBox="0 0 58.81 58.81"
                                        >
                                            <g
                                                id="Group_1721"
                                                data-name="Group 1721"
                                                transform="translate(54.81 54.81) rotate(180)"
                                            >
                                                <path
                                                    id="Path_1334"
                                                    data-name="Path 1334"
                                                    d="M0,35.928S26.934,9.211,35.928,0"
                                                    transform="translate(25.405 0) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1335"
                                                    data-name="Path 1335"
                                                    d="M0,0,25.542.056"
                                                    transform="translate(33.469 7.304) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1336"
                                                    data-name="Path 1336"
                                                    d="M0,0,25.431.056"
                                                    transform="translate(33.469 43.347) rotate(-45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div
                                        ref={comfortHabitacion1Next}
                                        className="comfort-slide-nav comfort-slide-next"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="62.353"
                                            height="62.353"
                                            viewBox="0 0 62.353 62.353"
                                        >
                                            <g
                                                id="Group_1722"
                                                data-name="Group 1722"
                                                transform="translate(-503.205 -10479.103)"
                                            >
                                                <path
                                                    id="Path_1334"
                                                    data-name="Path 1334"
                                                    d="M0,38.433S28.812,9.853,38.433,0"
                                                    transform="translate(534.381 10483.103) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1335"
                                                    data-name="Path 1335"
                                                    d="M0,0,27.323.06"
                                                    transform="translate(543.008 10490.916) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1336"
                                                    data-name="Path 1336"
                                                    d="M0,0,27.2.06"
                                                    transform="translate(543.007 10529.473) rotate(-45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                </div>

                                <div className="comfort-img-popup-btn"></div>

                                <div>
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        navigation={{
                                            prevEl: comfortHabitacion1Prev.current,
                                            nextEl: comfortHabitacion1Next.current,
                                        }}
                                        onInit={(swiper) => {
                                            swiper.params.navigation.prevEl =
                                                comfortHabitacion1Prev.current;
                                            swiper.params.navigation.nextEl =
                                                comfortHabitacion1Next.current;
                                            swiper.navigation.init();
                                            swiper.navigation.update();
                                        }}
                                    >
                                        <SwiperSlide>
                                            <img
                                                src={habitacion}
                                                alt="Habitacion"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={habitacion}
                                                alt="Habitacion"
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <p className="para-f2">Habitación</p>
                            </div>
                        ) : (
                            <div>
                                <div className="comfort-swiper-nav-container">
                                    <div
                                        ref={comfortHabitacion2Prev}
                                        className="comfort-slide-nav comfort-slide-prev"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="58.81"
                                            height="58.81"
                                            viewBox="0 0 58.81 58.81"
                                        >
                                            <g
                                                id="Group_1721"
                                                data-name="Group 1721"
                                                transform="translate(54.81 54.81) rotate(180)"
                                            >
                                                <path
                                                    id="Path_1334"
                                                    data-name="Path 1334"
                                                    d="M0,35.928S26.934,9.211,35.928,0"
                                                    transform="translate(25.405 0) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1335"
                                                    data-name="Path 1335"
                                                    d="M0,0,25.542.056"
                                                    transform="translate(33.469 7.304) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1336"
                                                    data-name="Path 1336"
                                                    d="M0,0,25.431.056"
                                                    transform="translate(33.469 43.347) rotate(-45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div
                                        ref={comfortHabitacion2Next}
                                        className="comfort-slide-nav comfort-slide-next"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="62.353"
                                            height="62.353"
                                            viewBox="0 0 62.353 62.353"
                                        >
                                            <g
                                                id="Group_1722"
                                                data-name="Group 1722"
                                                transform="translate(-503.205 -10479.103)"
                                            >
                                                <path
                                                    id="Path_1334"
                                                    data-name="Path 1334"
                                                    d="M0,38.433S28.812,9.853,38.433,0"
                                                    transform="translate(534.381 10483.103) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1335"
                                                    data-name="Path 1335"
                                                    d="M0,0,27.323.06"
                                                    transform="translate(543.008 10490.916) rotate(45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                                <path
                                                    id="Path_1336"
                                                    data-name="Path 1336"
                                                    d="M0,0,27.2.06"
                                                    transform="translate(543.007 10529.473) rotate(-45)"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeLinecap="round"
                                                    strokeWidth="4"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                </div>

                                <div className="comfort-img-popup-btn"></div>

                                <div>
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        navigation={{
                                            prevEl: comfortHabitacion2Prev.current,
                                            nextEl: comfortHabitacion2Next.current,
                                        }}
                                        onInit={(swiper) => {
                                            swiper.params.navigation.prevEl =
                                                comfortHabitacion2Prev.current;
                                            swiper.params.navigation.nextEl =
                                                comfortHabitacion2Next.current;
                                            swiper.navigation.init();
                                            swiper.navigation.update();
                                        }}
                                    >
                                        <SwiperSlide>
                                            <img
                                                src={habitacion}
                                                alt="Habitacion"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={habitacion}
                                                alt="Habitacion"
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <p className="para-f2">Habitación</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Comfort;
